import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedChannel } from '../../../../redux/slices/myGymTV/entertainmentSlice.js';
import './entertainment.scss';
import PropTypes from 'prop-types';

const AvaliableChannels = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { channels } = useSelector((state) => state.entertainment);
  console.log(channels);

  // Create a copy of the channels array and sort it
  const sortedChannels = [...channels].sort((a, b) => {
    if (a.WebNumber === '' && b.WebNumber !== '') {
      return -1;
    }
    if (a.WebNumber !== '' && b.WebNumber === '') {
      return 1;
    }
    return a.WebNumber.localeCompare(b.WebNumber);
  });

  const handleSetSelectedChannel = (channel) => {
    console.log(channel);
    dispatch(setSelectedChannel(channel));
    closeModal();
  };

  return (
    <ListGroup as="ul">
      {sortedChannels && sortedChannels.map((channel, index) => (
        <ListGroup.Item
          as="li"
          className="d-flex align-items-center pointer"
          key={index}
          onClick={() => handleSetSelectedChannel(channel)}
        >
          {channel.WebIconPath ?
            <img
              className="pe-4 entertainment__webIcon"
              src={`../../../${channel.WebIconPath}`}
            />
            : <img
              className="pe-4 entertainment__webIcon select-channel"
              src={'../../../images/channels/unknown.png'}
              />
          }
          <div>
            <div className="fw-bold">{channel.WebName}</div>
            <div className="text-muted">{`Channel: ${channel.ChannelID}`}</div>
            <div>
              <div>
                {channel.WebDescription}
              </div>
            </div>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

AvaliableChannels.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default AvaliableChannels;
