import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showCircleIcon: true,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleCircleIcon: (state) => {
      state.showCircleIcon = !state.showCircleIcon;
    },
  },
});

export const { toggleCircleIcon } = uiSlice.actions;
export default uiSlice.reducer;
