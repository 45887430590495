import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading = ({ message = 'Loading...' }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Spinner
        animation="border"
        className="loading-spinner"
        role="status"
      >
        <span className="visually-hidden">{message}</span>
      </Spinner>
    </div>
  );
};

export default Loading;
