import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchAdvertisers = createAsyncThunk(
  'advertiserAdmin/fetchAdvertisers',
  async (searchParam, thunkAPI) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/AdvertiserAdmin/SearchAdvertisers`, {
        params: { searchParam },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resendStatusEmail = createAsyncThunk(
  'advertiserAdmin/resendStatusEmail',
  async ({ advertiserCode, requestingEmail }, thunkAPI) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/AdvertiserAdmin/ResendStatusEmail`,
        { advertiserCode, RequestingUserEmail: requestingEmail },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const advertiserAdminSlice = createSlice({
  name: 'advertiserAdmin',
  initialState: {
    advertisers: [],
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdvertisers.pending, (state) => {
        state.loading = true;
        state.searchPerformed = true;
      })
      .addCase(fetchAdvertisers.fulfilled, (state, action) => {
        state.loading = false;
        state.advertisers = action.payload.length > 0 ? action.payload : [];
        state.error = action.payload.length > 0 ? null : 'No results found.';
      })
      .addCase(fetchAdvertisers.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch advertisers';
        state.loading = false;
      })
      .addCase(resendStatusEmail.fulfilled, (state, action) => {
        state.success = 'Email has been queued for delivery!';
        state.error = null;
      })
      .addCase(resendStatusEmail.rejected, (state, action) => {
        state.error = action.payload;
        state.success = null;
      });
  },
});

export const { clearMessages } = advertiserAdminSlice.actions;
export default advertiserAdminSlice.reducer;
