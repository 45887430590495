
export const getUserRoleCategories = (roles) => {
  const roleCategories = new Set();

  roles?.forEach(roleId => {
    if (roleId >= 1 && roleId <= 9) roleCategories.add('gymUser');
    else if (roleId === 10) roleCategories.add('admin');
    else if (roleId === 11) roleCategories.add('adUser');
  });
  return Array.from(roleCategories);
};

