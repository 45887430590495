import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Layout from '../../../../layout/Layout';
import ReplaceAll from './ReplaceAll';
import CopyDay from './CopyDay';
import ChannelReplace from './ChannelReplace';
import EditChannelByHour from './EditChannelByHour';
import EditNowPlaying from './EditNowPlaying';
import { useDispatch, useSelector } from 'react-redux';
import './entertainment.scss';


const ChannelEdit = () => {
  const dispatch = useDispatch();
  const { selectedVenue } = useSelector((state) => state.user);

  return (
    <Layout>
      <div className="entertainment-header">
        <div className="card-header">Channel Edit</div>

        <div
          className="card-header club__name"
          id="location-name"
        >{selectedVenue && ` -  ${selectedVenue.ZoneName}`}</div>
      </div>
      <Routes>
        <Route
          element={<CopyDay />}
          path="copy-day"
        />
        <Route
          element={<EditChannelByHour />}
          path="edit-channel-by-hour"
        />
        <Route
          element={<ReplaceAll />}
          path="replace-all"
        />
        <Route
          element={<ChannelReplace />}
          path="replace-channel"
        />
        <Route
          element={<EditNowPlaying />}
          path="edit-now-playing"
        />
      </Routes>
    </Layout>
  );
};

export default ChannelEdit;
