import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserRoleCategories } from '../helpers/roleAccessControl';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();

  // Redirect to login if no user is found
  //if (!user) {
  //  return <Navigate
  //    replace
  //    state={{ from: location }}
  //    to="/login"
  //         />;
  //}

  const userRoles = getUserRoleCategories(user?.RoleIds || []);
  const hasAccess = allowedRoles.some(role => userRoles.includes(role));

  return hasAccess ? children : <Navigate
    replace
    state={{ from: location }}
    to="/"
                                />;
};

export default ProtectedRoute;
