import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import '../order-tracker.scss';
import { useOrderStatus } from '../../../hooks/useOrderStatus';

const StepTracker = () => {

  const { activeKey, orderStatus } = useOrderStatus();
  const getLastCompletedIndex = () => {
    return Math.max(0, orderStatus);
  };

  return (
    <ButtonGroup className="oval-tracker mb-2 justify-content-center">
      {Array.from({ length: 7 }, (_, i) => i + 1).map(key => {
        const keyIndex = activeKey;
        const isCompleted = key < keyIndex;
        const isActive = key === keyIndex;
        const isLastCompleted = keyIndex === getLastCompletedIndex();

        return (
          <Button
            className={`${key} ${isCompleted || orderStatus === 8 ? 'completed' : ''} ${isActive ? 'active' : ''} ${isLastCompleted ? 'last-completed' : ''}`}
            key={key}
          >
            {(isCompleted || orderStatus === 8) && <FontAwesomeIcon icon={faCheck} />}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default StepTracker;
