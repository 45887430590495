import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faDumbbell } from '@fortawesome/free-solid-svg-icons';
import './order-tracker.scss';

const AdPortalHome = () => {
  const navigate = useNavigate();
  const { initialLoginData } = useSelector((state) => state.user);
  const account = initialLoginData?.Account;
  const agreements = initialLoginData?.Agreements;

    useEffect(() => {
      agreements?.forEach((agreement) => {
        if (agreement.AgreementID) {
          navigate(`/order-status/${agreement.AgreementID}`);
        } else {
          navigate('/account');
        }
      });
    }, [initialLoginData, agreements]);

  return (
    <FontAwesomeIcon
      className="text-center mt-5 text-secondary"
      icon={faDumbbell}
      size="2xl"
      spin
    />
  );
};

export default AdPortalHome;
