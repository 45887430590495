import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchProofStatuses = createAsyncThunk(
    'proofStatus/fetchStatuses',
    async (agreementID, thunkAPI) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/CRM/GetProofStatuses`, {
              params: { agreementID },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const proofStatusSlice = createSlice({
    name: 'proofStatus',
    initialState: {
        statuses: [],
        loading: false,
        error: null
    },
    reducers: {
        // Reducer logic can be added here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProofStatuses.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchProofStatuses.fulfilled, (state, action) => {
                state.statuses = action.payload;
                state.loading = false;
            })
            .addCase(fetchProofStatuses.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
});

export default proofStatusSlice.reducer;
