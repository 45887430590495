import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Layout from '../../../layout/Layout';
import { Button, Card, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import '../order-tracker.scss';
import WaitingOnAssets from './WaitingOnAssets';
import AssetsReceived from './AssetsReceived';
import InProduction from './InProduction';
import UnderReview from './UnderReview';
import Proofing from './Proofing';
import Approval from './Approval';
import Scheduled from './Scheduled';
import Loading from '../../Loading';
import { useOrderStatus } from '../../../hooks/useOrderStatus';

const OrderStatusPage = () => {
  const { agreementID } = useParams(); // Get agreementID from URL parameters
  const location = useLocation();
  const { initialLoginData } = location.state || {}; // Retrieve the passed data

  const [showOrderInfoModal, setShowOrderInfoModal] = useState(false);
  const { orderStatus, contractNumbers } = useOrderStatus();

  console.log(orderStatus);

  const handleShowOrderInfoModal = () => {
    setShowOrderInfoModal(true);
  };

  return (
    <Layout>
      <Card className="border-0">
        <Card.Body className="order-status p-0">
          <div className="row g-0 justify-content-center">
            {orderStatus > -1 ? (
              <Card className="col-lg-5 col-md-10 col-sm-10 border-0">
                <Card.Header className="d-flex justify-content-between align-items-center border-0 mb-0 pb-0">
                  <Card.Title className="d-flex justify-content-between align-items-center p-0">
                    Order Status
                    <FontAwesomeIcon
                      className="order-info ms-2"
                      icon={faCircleInfo}
                      onClick={() => { handleShowOrderInfoModal(); }}
                    />
                  </Card.Title>
                </Card.Header>
                <Card.Body className="align-items-center text-center p-0 g-0">
                  {orderStatus === 0 && <WaitingOnAssets />}
                  {orderStatus === 1 && <AssetsReceived />}
                  {orderStatus === 2 && <InProduction />}
                  {orderStatus === 3 && <UnderReview />}
                  {(orderStatus === 4 || orderStatus === 5 || orderStatus === 6 || orderStatus === 10) && <Proofing />}
                  {orderStatus === 7 && <Approval />}
                  {orderStatus === 8 && <Scheduled />}
                </Card.Body>
              </Card>
            ) : <Loading />}
          </div>
        </Card.Body>
      </Card>
      <Modal
        className="order-info-modal"
        onHide={() => setShowOrderInfoModal(false)}
        show={showOrderInfoModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>About Order Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Agreement {agreementID} includes these Contract Numbers:</p>
          <ul className="mt-2">
            {contractNumbers.map((contract, index) => (
              <li key={index}>{contract}</li>
            ))}
          </ul>
          <p className="mt-2">
            <strong>Please Note:</strong> The order status shown represents the contract that is in the earliest order state.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
onClick={() => setShowOrderInfoModal(false)}
variant="secondary"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default OrderStatusPage;
