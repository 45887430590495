import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { requestPasswordReset } from '../../../redux/slices/userSlice';
import { resendStatusEmail } from '../../../redux/slices/adPortal/advertiserAdminSlice';

const PasswordResetModal = ({ show, handleClose, initialEmail }) => {
  const [email, setEmail] = useState(initialEmail || '');
  const [resendStatusEmail, setResendStatusEmail] = useState(false);
  const dispatch = useDispatch();
  const { passwordResetStatus } = useSelector((state) => state.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(requestPasswordReset(email));
    if (resendStatusEmail) {
      dispatch(resendStatusEmail(email));
    }
  };

  return (
    <Modal
      centered
      onHide={handleClose}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            This feature will initiate the password reset process by sending an email to the customer.
          </p>
          <p>
            It will NOT work if the customer has not already created a password.
          </p>
          <p>
            For initial password creation, please use the resend Welcome Email feature.
          </p>
        </div>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              required
              type="email"
              value={email}
            />
          </Form.Group>
          <Button
            type="submit"
            variant="primary"
          >
            Send Reset Link
          </Button>
          {passwordResetStatus && <p className="mt-3">{passwordResetStatus}</p>}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordResetModal;
