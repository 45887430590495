import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUserData, checkPasswordResetRequested } from '../redux/slices/userSlice';
import { getUserRoleCategories } from '../helpers/roleAccessControl';

const useLoginRedirect = () => {
  const { user, initialLoginData } = useSelector((state) => state.user);
  const agreementID = user && initialLoginData?.Agreements?.length > 0 ? initialLoginData.Agreements[0].AgreementID : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(getUserData());
    }
  }, [dispatch]);

  useEffect(() => {
    if (username) {
      dispatch(checkPasswordResetRequested(username))
        .then((action) => {
          if (action.payload && action.payload.PasswordResetRequested) {
            navigate(`/login?username=${username}`);
          } else {
            navigate('/login');
          }
        })
        .catch((error) => {
          console.error('Error checking password reset status:', error);
          navigate('/login');
        });
    } else if (user) {
      const userRoles = getUserRoleCategories(user.RoleIds);

      if (userRoles.includes('adUser') && userRoles.includes('admin')) {
        navigate('/admin');
      } else if (userRoles.includes('adUser')) {
        navigate(`/order-status/${agreementID}`);
      } else if (userRoles.includes('gymUser')) {
        navigate('/content-overview');
      }
    } else {
      navigate('/login');
    }
  }, [user, navigate, dispatch, username, agreementID]);

};

export default useLoginRedirect;
