export const normalizeStatus = (status) => {
  console.log('s',status)
  const statusNormalizations = {
    // AssetsReceived
    'AssetsReceived': 'AssetsReceived',
    'Assets Received': 'AssetsReceived',

    // Approval
    'ClientAdApproval': 'Approval',
    'InternalAdApproval': 'Approval',
    'PostBroadcastRevisionApproved': 'Approval',

    // Created
    'Created': 'Created',

    // InProduction
    'InProduction': 'InProduction',
    'In Production': 'InProduction',
    'PendingSyndicatedAdVoiceOver': 'InProduction',

    // ProofSent (Assuming Proof Ready also maps here)
    'ProofSent': 'ProofSent',
    'Proof Ready': 'ProofSent',
    'ProofReady': 'ProofSent',
    'ProofRevisionSent': 'ProofSent',
    'Proof Revision Ready': 'ProofSent',
    'PostBroadcastRevisionsent': 'ProofSent',
    'ProofRevisionCompleted': 'ProofSent',
    'PostBroadcastRevisionCompleted': 'ProofSent',

    // ProofRevisionNeeded
    'ProofRevisionNeeded': 'ProofRevisionNeeded',
    'PostBroadcastRevisionNeeded': 'ProofRevisionNeeded',



    // UnderReview
    'UnderReview': 'UnderReview',
    'Under Review': 'UnderReview',
    'InHouseRevisionNeeded': 'UnderReview',

    // WaitingOnAssets
    'WaitingOnAssets': 'WaitingOnAssets',
    'Waiting On Assets': 'WaitingOnAssets'
  };
  return statusNormalizations[status.replace(/\s+/g, '')] || status;
};
