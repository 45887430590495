import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';


const tomorrow = moment().add(1, 'day');
const tomorrowFormatted = tomorrow.format('MM/DD/YYYY');

for (let i = 0; i < 50; i++) {
  const isActive = Math.random() < 0.5;
  const isScheduled = !isActive && Math.random() < .7;
  const fromDate = tomorrowFormatted;

  const text = {
    message: '',
    active: isActive,
    scheduled: isScheduled,
    fromDate: fromDate,
    id: uuidv4()
  };
}

export const getScrollingMessages = createAsyncThunk(
  'scrollingMessages/GetScrollingMessages',
  async (arg, { getState, dispatch }) => {
    const { user } = getState();
    const { selectedVenue } = user;
    const token = localStorage.getItem('token');
    const params = {
      clubID: selectedVenue.EntityID
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/ScrollingMessages/GetMessages`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const scrollingMessages = response.data;
      dispatch(setScrollingMessages(scrollingMessages));
      return response.data;

    } catch (error) {
      console.error('error', error);
    }
  }
);

export const saveScrollingMessages = createAsyncThunk(
  'scrollingMessages/SaveScrollingMessages',
  async (newScrollingMessages, { getState }) => {
    const { user, scrollingText } = getState();
    const { selectedVenue } = user;
    const messages = scrollingText.scrollingMessages || [];
    console.log('messages', messages)
    console.log('new', newScrollingMessages);
    const existingMessagesMap = new Map(
      messages.map(msg => {
        console.log('Processing message:', msg);
        return [msg.Sequence, msg];
      })
    );
    console.log(existingMessagesMap);

    let highestSequence = Math.max(...existingMessagesMap.keys(), 0);

    newScrollingMessages.forEach(newMessage => {
      if (existingMessagesMap.has(newMessage.Sequence)) {

        existingMessagesMap.set(newMessage.Sequence, newMessage);
      } else {
        highestSequence++;
        existingMessagesMap.set(highestSequence, newMessage);
      }
    });

    const allMessages = [...existingMessagesMap.values()].map((message, index) => ({
      Sequence: index + 1,
      Message: message.Message,
      startDate: message.startDate || message.StartDate,
      endDate: message.endDate || message.EndDate,
      Active: true
    }));

    const payload = {
      venueID: selectedVenue.EntityID,
      groupID: scrollingText.scrollingMessages?.[0]?.GroupID || 0,
      scrollMessages: allMessages.reduce((acc, message, index) => {
        acc['message' + (index + 1)] = message;
        return acc;
      }, {})
    };
    console.log(payload);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/ScrollingMessages/SaveMessages`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);



const scrollingMessagesSlice = createSlice({
  name: 'scrollingMessages',
  initialState: {
    scrollingMessages: [],
  },
  reducers: {
    getScrollingMessages: (state) => state,
    setScrollingMessages: (state, action) => {
      state.scrollingMessages = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getScrollingMessages.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getScrollingMessages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.scrollingMessages = action.payload;
      })
      .addCase(getScrollingMessages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(saveScrollingMessages.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(saveScrollingMessages.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(saveScrollingMessages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});


export const { setScrollingMessages, addScrollingText, deleteScrollingText } = scrollingMessagesSlice.actions;

export default scrollingMessagesSlice.reducer;
