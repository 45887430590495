import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AvaliableChannels from './AvailableChannels';
import { Card, Modal, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateChannelPrefsByDayOrTime,
  updateChannelPrefsAllDay,
  updateChannelCopyToMoreDays,
  updateChannelCopyToMoreDaysAllDay
} from '../../../../redux/slices/myGymTV/entertainmentSlice.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import { getDayOfWeekAsInt } from '../../../../helpers/transformations';
import './entertainment.scss';

const EditChannelByHour = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showAvailableChannels, setShowAvailableChannels] = useState(false);
  const handleCloseAvailableChannels = () => setShowAvailableChannels(false);
  const handleShowAvailableChannels = () => setShowAvailableChannels(true);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const { selectedDay, selectedHour, selectedChannel, editingChannel } = useSelector((state) => state.entertainment);
  const [selectedDays, setSelectedDays] = useState([]);
  useEffect(() => {
    selectedDays.length && console.log(selectedDays)
  }, [selectedDays])

  const [selectedHours, setSelectedHours] = useState([]);
  const [selectedHourRange, setSelectedHourRange] = useState([]);
  useEffect(() => {
    selectedHours.length && console.log(selectedHours)
  }, [selectedHours])

  const [isDaysListOpen, setIsDaysListOpen] = useState(false);

  const handleDaysListToggle = () => {
    setIsDaysListOpen(!isDaysListOpen);
  };

  const [isDayPartOpen, setIsDayPartOpen] = useState(false);
  const handleDayPartToggle = () => {
    setIsDayPartOpen(!isDayPartOpen);
  };

  const [copyToMoreDays, setCopyToMoreDays] = useState(false);

  const handleCopyToMoreDaysToggle = () => {
    setCopyToMoreDays(!copyToMoreDays);
  }

  const [copyToMoreHours, setCopyToMoreHours] = useState(false);

  const handleCopyToMoreHoursToggle = () => {
    setCopyToMoreHours(!copyToMoreHours);
  }
  const [useAllDay, setUseAllDay] = useState(false);
  const handleUseAllDayToggle = () => {
    setUseAllDay(!useAllDay);
  }

  const daysOfWeek = [
    { id: 1, name: 'Sunday', disabled: selectedDay.label === 'Sunday', checked: false },
    { id: 2, name: 'Monday', disabled: selectedDay.label === 'Monday', checked: false },
    { id: 3, name: 'Tuesday', disabled: selectedDay.label === 'Tuesday', checked: false },
    { id: 4, name: 'Wednesday', disabled: selectedDay.label === 'Wednesday', checked: false },
    { id: 5, name: 'Thursday', disabled: selectedDay.label === 'Thursday', checked: false },
    { id: 6, name: 'Friday', disabled: selectedDay.label === 'Friday', checked: false },
    { id: 7, name: 'Saturday', disabled: selectedDay.label === 'Saturday', checked: false },
  ];

  const hoursOfDay = Array.from({ length: 24 }, (_, i) => {
    let hourLabel = '';

    if (i === 0) {
      hourLabel = '12am';
    } else if (i < 12) {
      hourLabel = `${i}am`;
    } else if (i === 12) {
      hourLabel = '12pm';
    } else {
      hourLabel = `${i - 12}pm`;
    }

    return {
      id: i,
      name: hourLabel,
      disabled: false,
      checked: false,
    };
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);


  const handleSelectDay = (selectedDayData) => {
    setSelectedDays([]);
    daysOfWeek
      .filter((day) => document.getElementById(day.name).checked)
      .map((day) => {
        setSelectedDays(prevSelectedDays => [...prevSelectedDays,
        {
          name: day.name,
          data: selectedDayData
        }])
      });
    setShowConfirmationModal(false);
  };

  const handleSelectHour = (selectedHourData) => {
    setSelectedHours([]);
    hoursOfDay
      .filter((hour) => document.getElementById(hour.name).checked)
      .map((hour) => {
        setSelectedHours(prevSelectedHours => [...prevSelectedHours,
        {
          name: hour.name,
          data: selectedHourData
        }])
      });
    setShowConfirmationModal(false);
  };

  const getHourFromRange = (hourLabel) => {
    if (hourLabel === '12am') return 0;
    if (hourLabel.endsWith('am')) {
      return parseInt(hourLabel, 10);
    }
    if (hourLabel === '12pm') return 12;
    if (hourLabel.endsWith('pm')) {
      return 12 + parseInt(hourLabel, 10);
    }
    return -1; // should not reach here for valid inputs
  };

  const handleSaveChanges = () => {
    const selectedDayInt = getDayOfWeekAsInt(selectedDay.label);
    const selectedDaysInts = selectedDays.map(day => getDayOfWeekAsInt(day.name));

    if (useAllDay && !copyToMoreDays) {
      dispatch(updateChannelPrefsAllDay({
        selectedChannel: selectedChannel.ChannelID ? selectedChannel.ChannelID : editingChannel.ChannelID,
        selectedDay: selectedDayInt
      }));
    } else if (copyToMoreDays && !useAllDay && !copyToMoreHours) {
      console.log(selectedChannel, editingChannel);
      dispatch(updateChannelCopyToMoreDays({
        selectedChannel: selectedChannel.ChannelID ? selectedChannel.ChannelID : editingChannel.ChannelID,
        selectedDay: selectedDayInt,
        selectedDays: selectedDaysInts,
        selectedHour: selectedHour
      }));
    } else if (copyToMoreDays && useAllDay) {
      dispatch(updateChannelCopyToMoreDaysAllDay({
        selectedChannel: selectedChannel.ChannelID ? selectedChannel.ChannelID : editingChannel.ChannelID,
        selectedDay: selectedDayInt,
        selectedDays: selectedDaysInts
      }));
    } else if (copyToMoreHours && !copyToMoreDays) {
      selectedHours.forEach(hour => {
        const hourInt = getHourFromRange(hour.name);
        console.log(selectedChannel, editingChannel.ID)
        dispatch(updateChannelPrefsByDayOrTime({
          selectedChannel: selectedChannel.ChannelID ? selectedChannel.ChannelID : editingChannel.ChannelID,
          selectedDay: selectedDayInt,
          selectedHour: hourInt
        }));
      });
    } else {
      dispatch(updateChannelPrefsByDayOrTime({
        selectedChannel: selectedChannel.ChannelID ? selectedChannel.ChannelID : editingChannel.ChannelID,
        selectedDay: selectedDayInt,
        selectedHour: selectedHour
      }));
    }

    setShowConfirmationModal(false);
    navigate('/entertainment');
  }

  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  let currentDate = new Date();
  let currentDay = currentDate.getDay();
  currentDay = (currentDay === 0) ? 7 : currentDay;

  return (
    <Card
      className="border-0"
      id="edit-hours"
    >
      {Object.keys(selectedDay).length > 0 &&

        <Card.Body>
          <Card.Title className="py-2"> {editingChannel.DayLabel}s: {editingChannel.HourRange}</Card.Title>

          {editingChannel &&
            <>
              <div className="h5 text-secondary border-bottom mt-4">Currently Scheduled</div>
              <div className="d-flex align-items-center py-2">

                {editingChannel.WebIconPath ?
                  <img
                    className="pe-4 entertainment__webIcon select-channel"
                  src={`../../../${editingChannel.WebIconPath}`}
                  />
                : <img
                  className="pe-4 entertainment__webIcon select-channel"
                  src={'../../../images/channels/unknown.png'}
                  />
                }
                <div>
                  <div className="fw-bold">{editingChannel.WebName}
                  </div>
                  <div className="text-muted">{`Channel: ${editingChannel.ChannelID}`}</div>
                </div>
              </div>
              <div />
            </>
          }

          {
            Object.keys(selectedChannel).length ?
              <>

                <div className="h5 text-secondary border-bottom mt-3">Selected Channel</div>
                <div className="d-flex align-items-center pb-4">

                  {selectedChannel.WebIconPath ?
                    <img
                      className="pe-4 entertainment__webIcon select-channel"
                      src={`../../../${selectedChannel.WebIconPath}`}
                    />
                    : <img
                      className="pe-4 entertainment__webIcon select-channel"
                      src={'../../../images/channels/unknown.png'}
                      />

                  }
                  <div>
                    <div className="fw-bold">{selectedChannel.WebName}
                      <FontAwesomeIcon
                        className="ms-3 text-secondary"
                        icon={faPen}
                        onClick={() => {
                          handleShowAvailableChannels();
                        }}
                      />
                    </div>
                    <div className="text-muted">{`Channel: ${selectedChannel.ChannelID}`}</div>
                  </div>
                </div>
              </>
              :
              <Button
                className="w-100 my-4"
                onClick={() => {
                  handleShowAvailableChannels()
                }}
              >
                Select a New Channel
              </Button>
          }

          {showAdvancedSettings && (
            <>

              <div className="mb-2 d-flex align-items-center form-check form-switch">
                <input
                  checked={copyToMoreHours}
                  className="me-4 form-check-input"
                  id="custom-switch"
                  onChange={handleCopyToMoreHoursToggle}
                  type="checkbox"
                />
                <label className="mb-0 form-check-label">Copy this change to more hours</label>
              </div>
              <div className="h-50">
                {copyToMoreHours && (
                  <div className="d-flex justify-content-end">
                    <ListGroup>
                      <ListGroupItem
                        className="ps-5 d-flex align-items-center form-check form-switch"
                      >
                        <input
                          checked={useAllDay}
                          className="me-4 form-check-input"
                          id="custom-switch"
                          onChange={handleUseAllDayToggle}
                          type="checkbox"
                        />
                        <label className="h5 mb-0 form-check-label">All day</label>
                      </ListGroupItem>
                      {
                        hoursOfDay.map(hour => (
                          <ListGroupItem
                            className="ps-5 d-flex align-items-center form-check form-switch"
                            key={hour.id}
                          >
                            <input
                              className="me-4 form-check-input"
                              id={hour.name}
                              onChange={() => { handleSelectHour(selectedHour) }}
                              type="checkbox"
                            />
                            <label
                              className="h5 mb-0 form-check-label"
                              htmlFor={hour.name}
                            >
                              {hour.name}
                            </label>
                          </ListGroupItem>
                        ))
                      }
                    </ListGroup>
                  </div>
                )}
              </div>
              <div className="mb-2 d-flex align-items-center form-check form-switch">
                <input
                  checked={copyToMoreDays}
                  className="me-4 form-check-input"
                  id="custom-switch"
                  onChange={handleCopyToMoreDaysToggle}
                  type="checkbox"
                />
                <label className="mb-0 form-check-label">Copy this change to more days</label>
              </div>
              {copyToMoreDays && (
                <div className="d-flex justify-content-end">
                  <ListGroup className="">
                    {
                      daysOfWeek.map(day => (
                        <ListGroupItem
                          className="ps-5 d-flex align-items-center form-check form-switch"
                          key={day.id}
                        >
                          <input
                            className="me-4 form-check-input"
                            disabled={day.disabled}
                            id={day.name}
                            onChange={() => { handleSelectDay(selectedDay) }}
                            type="checkbox"
                          />
                          <label
                            className="h5 mb-0 form-check-label"
                            htmlFor={day.name}
                          >
                            {day.name}
                          </label>
                        </ListGroupItem>
                      ))
                    }
                  </ListGroup>
                </div>
              )}

            </>
          )}
          {selectedChannel && (
            <>
              {!showAdvancedSettings ?
                <Button
                  className="w-100 mb-4"
                  onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                >
                  Advanced Options
                </Button>
                :
                <Button
                  className="w-100 mb-4"
                  onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                >
                  Hide Advanced Settings
                </Button>
              }
              <Button
                className="w-100 mb-4"
                onClick={openConfirmationModal}
              >
                Save Changes
              </Button>
            </>
          )}


          <Modal
            onHide={closeConfirmationModal}
            show={showConfirmationModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {(useAllDay && !copyToMoreDays) && `Are you sure you want to update ${editingChannel.DayLabel} to play "${selectedChannel.WebName}" all day?`}
              {(!useAllDay && copyToMoreDays) && `Are you sure you want to update the selected days at ${editingChannel.HourRange} to play "${selectedChannel.WebName}?"`}
              {(useAllDay && copyToMoreDays) && `Are you sure you want to update the selected days to play "${selectedChannel.WebName}" all day?`}
              {(!useAllDay && !copyToMoreDays) && `Are you sure you want to update ${editingChannel.DayLabel} at ${editingChannel.HourRange} to play "${selectedChannel.WebName}?`}

            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={closeConfirmationModal}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveChanges}
                variant="primary"
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            className="m-0"
            onHide={handleCloseAvailableChannels}
            show={showAvailableChannels}
          >
            <Modal.Header closeButton>
              <Modal.Title>Your Channels</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AvaliableChannels closeModal={handleCloseAvailableChannels} />
            </Modal.Body>
          </Modal>
        </Card.Body>
      }
    </Card>
  );
};

export default EditChannelByHour;
