import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import entertainmentReducer from './slices/myGymTV/entertainmentSlice';
import signageReducer from './slices/myGymTV/signageSlice';
import scrollingMessagesReducer from './slices/myGymTV/scrollingMessagesSlice';
import timeReducer from './slices/myGymTV/timeSlice';
import proofsReducer from './slices/adPortal/proofSlice'
import advertiserAdminReducer from './slices/adPortal/advertiserAdminSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    signage: signageReducer,
    scrollingText: scrollingMessagesReducer,
    entertainment: entertainmentReducer,
    time: timeReducer, 
    proofStatus: proofsReducer,
    advertiserAdmin: advertiserAdminReducer
  },
});
