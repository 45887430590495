import React, { useState } from 'react';
import { Card, ButtonGroup, Button, Form } from 'react-bootstrap';
import '../order-tracker.scss';
import StepTracker from './StepTracker';

const ProofRevisionNeeded = () => {
  const [proofStatus, setProofStatus] = useState('initial');
  const [showRevisionForm, setShowRevisionForm] = useState(false);

  //const handleSubmit = async (event) => {
  //  const token = localStorage.getItem('token');
  //  event.preventDefault();

  //  // Creating an array of arrays from formData
  //  const jsonformData = [
  //    {
  //      'directory': '\\\\sweb5\\ClubCom\\Websites\\AdPortalContent\\',
  //      'question': 'Product Description',
  //      'answer': formData.productDescription
  //    }
  //  ]

  //  let textData = jsonformData.map(item =>
  //    `${item.question}\n${item.answer}\n\n`
  //  ).join('');

  //  const textBlob = new Blob([textData], { type: 'text/plain' });

  //  // Converting the array of arrays to a JSON string
  //  const jsonString = JSON.stringify(jsonformData);

  //  const textFormData = new FormData();
  //  textFormData.append('file', textBlob, `${advertiserCode}.txt`);
  //  textFormData.append('advertiserCode', advertiserCode);

  //  try {
  //    await axios.post(`${process.env.REACT_APP_BASE_URL}/api/AdPortalUpload/UploadFile`, textFormData);
  //    setShowSuccessModal(true);
  //    clearFormData();

  //    // After successful upload, submit Ad Tracker Update
  //    await submitAdTrackerUpdate(jsonString);
  //  } catch (error) {
  //    console.error('Error uploading form data:', error);
  //  }
  //};


  //const submitAdTrackerUpdate = async (jsonString) => {
  //  const token = localStorage.getItem('token');
  //  const workQueueItemData = {
  //    TypeID: 3,
  //    AgreementID: parseInt(agreementID),
  //    UserID: user.UserID,
  //    Data: jsonString
  //  };

  //  try {
  //    await axios.post(`${process.env.REACT_APP_BASE_URL}/api/CRM/SubmitAdTrackerUpdate`, workQueueItemData, {
  //      headers: {
  //        Authorization: `Bearer ${token}`
  //      }
  //    });
  //    /*      dispatch(getInitialLoginData(user.UserID))*/
  //    console.log('Ad Tracker Update submitted successfully');
  //  } catch (error) {
  //    console.error('Error submitting Ad Tracker Update:', error);
  //  }
  //};


  return (
    <div className="status-bg">
      <StepTracker />
      <Card className="border-0 status-description">
        <Card.Body className="pt-0">
          {proofStatus === 'initial' && (
            <>
              <Card.Title
                className="step-title text-center d-flex justify-content-center align-items-center"
              >
                Proof Revision Needed
              </Card.Title>
              <Card.Text>
                Your Ad is now in the queue to be revised by the designer.
              </Card.Text>
            </>
          )}
        </Card.Body>
      </Card>
    </div>

  );
};

export default ProofRevisionNeeded;
