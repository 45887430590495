import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { registerUser } from '../../../redux/slices/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const NewUser = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const validateForm = () => {
    // Add your validation logic here
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    const userDetails = {
      Email: email,
      Password: password,
    };

    try {
      const response = await dispatch(registerUser(userDetails));
      if (response.payload) {
        setShowModal(true);
      }
    } catch (error) {
      console.log('it dies here');
    }

    setEmail('');
    setPassword('');
    setErrors({});
  };

  return (
    <>
      <Modal
        onHide={handleCloseModal}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon
              className="text-whtie"
              icon={faCircleExclamation}
              size="2xl"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="ms-2 text-success alert alert-success">Successfully Created User!</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCloseModal}
            variant="secondary"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

        <div className="container">
          <div className="row d-flex align-items-center justify-content-center">

              <form onSubmit={handleSubmit}>
                <div className="form-outline mb-4">
                  <input
                    className="form-control form-control-lg"
                    id="form1Example1"
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    value={email}
                  />
                  <label
                    className="form-label"
                    htmlFor="form1Example1"
                  >
                    Email address
                  </label>
                </div>
                <div className="form-outline mb-4">
                  <input
                    className="form-control form-control-lg"
                    id="form1Example2"
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    value={password}
                  />
                  <label
                    className="form-label"
                    htmlFor="form1Example2"
                  >
                    Password
                  </label>
                </div>
                <div className="w-100">
                  <Button
                    className="btn-lg w-100"
                    type="submit"
                    variant="primary"
                  >
                    Create Account
                  </Button>
                </div>
              </form>
          </div>
        </div>
    </>
  );
};

export default NewUser;
