import React from 'react';
import { useMediaQuery } from 'react-responsive';
import TopNav from '../features/MyGymTV/Navigation/TopNav';
import Nav from '../features/MyGymTV/Navigation/Nav';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const Layout = ({ children }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 1019px)',
  });

  const { selectedVenue } = useSelector((state) => state.user)
  const { initialLoginData } = useSelector((state) => state.user);
  const adAccount = initialLoginData?.Account;

  return (
    <>
      {isMobile ? (
        <div style={{ height: '100%' }}>
          <TopNav />
          <main
            className="mobile"
            style={{
              marginTop: '40px',
              paddingBottom: '125px',
              minHeight: '100%',
            }}
          >
            {children}
          </main>
          {(selectedVenue || adAccount) && <Nav />}
        </div>
      ) : (
        <div className='d-flex'>
          <Nav />
          <main className='desktop-main'>{children}</main>
        </div>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout;
