import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { validateResetToken } from '../../redux/slices/userSlice';
import Loading from '../Loading';

const ValidateResetTokenComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { isValidToken, email, isLoading, error } = useSelector((state) => state.user);
  useEffect(() => {
    if (isValidToken === null && token && !isLoading) {
      dispatch(validateResetToken(token));
    }
  }, [dispatch, token, isValidToken, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      if (isValidToken) {
        navigate(`/login?username=${email}`);
      } else if (isValidToken === false) {
        alert('Invalid or expired token.');
        navigate('/login');
      }
    }
  }, [isValidToken, email, isLoading, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return <div>Validating token, please wait...</div>;
};


export default ValidateResetTokenComponent;
