import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { resendStatusEmail, clearMessages } from '../../../redux/slices/adPortal/advertiserAdminSlice';

const ResendEmail = ({ show, handleClose, initialAdvertiserCode }) => {
  const [advertiserCode, setAdvertiserCode] = useState(initialAdvertiserCode || '');
  const dispatch = useDispatch();
  const { error, success } = useSelector((state) => state.advertiserAdmin);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    setAdvertiserCode(initialAdvertiserCode || '');
  }, [initialAdvertiserCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(resendStatusEmail({ advertiserCode, requestingEmail: user.Email }));
  };

  const handleSuccessClose = () => {
    dispatch(clearMessages());
    handleClose();
  };

  return (
    <Modal
centered
onHide={handleClose}
show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>Resend Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <div>
            <p>{success}</p>
            <Button
onClick={handleSuccessClose}
variant="primary"
            >
              Close
            </Button>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Advertiser Code</Form.Label>
              <Form.Control
                onChange={(e) => setAdvertiserCode(e.target.value)}
                placeholder="Enter Advertiser Code"
                required
                type="text"
                value={advertiserCode}
              />
            </Form.Group>
            <Button
type="submit"
variant="primary"
            >
              Send
            </Button>
            {error && <p className="mt-3 text-danger">{error}</p>}
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ResendEmail;
