import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AvaliableChannels from './AvailableChannels';
import { Card, Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateChannelReplaceAll } from '../../../../redux/slices/myGymTV/entertainmentSlice.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCompactDisc, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import './entertainment.scss';

const ChannelReplace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAvailableChannels, setShowAvailableChannels] = useState(false);
  const handleCloseAvailableChannels = () => setShowAvailableChannels(false);
  const handleShowAvailableChannels = () => setShowAvailableChannels(true);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { selectedChannel, currentChannel } = useSelector((state) => state.entertainment);
 
  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleChannelReplaceAll = () => {
    dispatch(updateChannelReplaceAll({
      newChannelId: selectedChannel.ChannelID,
      currentChannelId: currentChannel.ChannelID,
      currentChannel: currentChannel
    }));
    closeConfirmationModal();
    navigate('/entertainment');
  }


  return (
    <Card
      className="border-0"
      id="copy-day"
    >
      <Card.Body>
        <Card.Body>
          <Card.Title className="py-2"> Replace Channel</Card.Title>
          <div
            className="alert alert-secondary d-flex justify-content-between align-items-center"
            role="alert"
          >
            <FontAwesomeIcon
              className="text-secondary"
              icon={faCircleInfo}
            />
            <div className="ms-2 entertainment__action-info">Replace a channel with another channel, everywhere it occurs on the schedule.</div>
          </div>

          <div>
            <div className="h5 text-secondary border-bottom mt-4">Now Playing</div>
            {currentChannel && 
              <div className="d-flex align-items-center py-2">

                {currentChannel.WebIconPath ?
                  <img
                    className="pe-4 entertainment__webIcon select-channel"
                    src={`${currentChannel.WebIconPath}`}
                  />
                  : <img
                    className="pe-4 entertainment__webIcon select-channel"
                    src={'../../../images/channels/unknown.png'}
                    />

                }
                <div>
                  <div className="fw-bold">{currentChannel.WebName}
                    <FontAwesomeIcon
                      className="ms-3 text-secondary"
                      icon={faPen}
                      onClick={() => {
                        handleShowAvailableChannels();
                      }}
                    />
                  </div>
                  <div className="text-muted">{`Channel: ${currentChannel.ChannelID}`}</div>
                </div>
              </div>
            }
            <div />
          </div>
          {selectedChannel &&
            <>
              <div className="h5 text-secondary border-bottom mt-4">Replace With</div>
              <div className="d-flex align-items-center py-2">

                {selectedChannel.WebIconPath ?
                  <img
                    className="pe-4 entertainment__webIcon select-        channel"
                    src={`${selectedChannel.WebIconPath}`}
                  />
                : <img
                  className="pe-4 entertainment__webIcon select-channel"
                  src={'../../../images/channels/unknown.png'}
                  />

                }
                <div>
                  <div className="fw-bold">{selectedChannel.WebName}
                    <FontAwesomeIcon
                      className="ms-3 text-secondary"
                      icon={faPen}
                      onClick={() => {
                        handleShowAvailableChannels();
                      }}
                    />
                  </div>
                  <div className="text-muted">{`Channel: ${selectedChannel.ChannelID}`}</div>
                </div>
              </div>
            </>
         }

          <Modal
            className="m-0"
            onHide={handleCloseAvailableChannels}
            show={showAvailableChannels}
          >
            <Modal.Header closeButton>
              <Modal.Title>Your Channels</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AvaliableChannels closeModal={handleCloseAvailableChannels} />
            </Modal.Body>
          </Modal>
        </Card.Body>

        <Button
          className="w-100"
          disabled={!selectedChannel}
          onClick={openConfirmationModal}
        >
          Save Changes
        </Button>
        {selectedChannel &&
          <Modal
            onHide={closeConfirmationModal}
            show={showConfirmationModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to replace "{ currentChannel.WebName }" with "{selectedChannel.WebName}"" everywhere in the schedule?
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={closeConfirmationModal}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleChannelReplaceAll()}
                variant="primary"
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        }

      </Card.Body>
    </Card>
  );
};

export default ChannelReplace;
