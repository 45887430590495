import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import VenueSelect from './VenueSelect';
import GymTvLogo from '../../../images/MyGymTV-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faUser } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCircleIcon } from '../../../redux/slices/myGymTV/uiSlice';
import './top-nav.scss';


const TopNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedVenue, initialLoginData } = useSelector((state) => state.user);
  const handleNavigation = (target) => {
    target === '/content-overview' ? navigate('/content-overview') : navigate('/gym-account');
    dispatch(toggleCircleIcon());
  };

  const classNames = `mobile top-nav d-flex ${!selectedVenue ? 'justify-content-center' : 'justify-content-between'} align-items-center`;

  return (
    <Navbar
      className={classNames}
      fixed='top'
    >
      {selectedVenue &&
        <FontAwesomeIcon
          className='icon-home'
          icon={faHouse}
          onClick={() => handleNavigation('/content-overview')}
          style={{ height: '1rem' }}
        />
      }

      {selectedVenue ?
        <VenueSelect /> :
        <div
          className="text-center text-white"
        >
          <img
            alt="Zoom Logo"
            className="py-4 w-25"
            src={GymTvLogo}
            style={{ width: '150px' }}
          />
        </div>
      }

      {selectedVenue &&
        <FontAwesomeIcon
          className='icon-home'
          icon={faUser}
          onClick={() => handleNavigation('account')}
          style={{ height: '1rem' }}
        />
      }

    </Navbar>
  );
};

export default TopNav;
