import React from 'react';
import Card from 'react-bootstrap/Card';
import '../order-tracker.scss';
import StepTracker from './StepTracker';

const AssetsReceived = () => {
  return (
    <div className="status-bg">
      <StepTracker />
      <Card className="border-0 status-description">
        <Card.Body className="pt-0">
          <Card.Title
            className="step-title text-center d-flex justify-content-center align-items-center"
          >
            Assets Received
          </Card.Title>
          <Card.Text>
            We have received your assets. We will be reviewing
            creative submissions to prepare your ad for production.
          </Card.Text>

        </Card.Body>
      </Card>
    </div>

  );
};

export default AssetsReceived;
