import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { normalizeStatus } from '../helpers/statusNormalizer';

export const useOrderStatus = () => {
  const { initialLoginData } = useSelector((state) => state.user);
  const agreements = initialLoginData?.Agreements;

  const [orderStatus, setOrderStatus] = useState(null);
  const [statusAlert, setStatusAlert] = useState(false);
  const [contractNumbers, setContractNumbers] = useState([]);
  const [orderStatusLabel, setOrderStatusLabel] = useState('');
  const [activeKey, setActiveKey] = useState(0);

  
  const orderStatusToKeyIndexMap = {
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 5,
    6: 5,
    7: 6,
    8: 7,
    9: 8,
    10: 9,
    11: 10,
    12: 11
  };

  useEffect(() => {
    const status = [];
    const tempContractNumbers = [];
    if (agreements) {
      agreements.forEach(agreement => {
        agreement.Contracts.forEach(contract => {
          const normalizedStatus = normalizeStatus(contract.WebAdStatusDisplayName || contract.AdStatus);
          status.push(normalizedStatus);
          tempContractNumbers.push(contract.ContractNumber);
        });
      });
      setContractNumbers(tempContractNumbers);
      console.log('status', status);
      const statusMap = new Map([
        ['WaitingOnAssets', 0],
        ['AssetsReceived', 1],
        ['InProduction', 2],
        ['UnderReview', 3],
        ['ProofSent', 4],
        ['ProofRevisionNeeded', 5],
        ['ProofRevisionSent', 6],
        ['Approval', 7],
        ['Scheduled', 8],
        ['PostBroadcastRevisionNeeded', 9],
        ['PostBroadcastRevisionSent', 10],
        ['PostBroadcastRevisionApproved', 11]
      ]);

      const statusLabelMap = new Map([
        [0, 'Waiting On Assets'],
        [1, 'Assets Received'],
        [2, 'In Production'],
        [3, 'Under Review'],
        [4, 'Proofs Ready'],
        [5, 'Proof Revision Needed'],
        [6, 'Proof Revision Sent'],
        [7, 'Client Ad Approval'],
        [8, 'Scheduled'],
        [9, 'Proof Revision Needed'],
        [10, 'Proof Revision Ready'],
        [11, 'Approval'],
      ]);

      const lowestIndexStatus = status
        .map(statusItem => statusMap.get(statusItem))
        .filter(index => index !== undefined)
        .sort((a, b) => a - b)[0];

      setOrderStatus(lowestIndexStatus);
      setStatusAlert([0, 4, 6, 7, 10].includes(lowestIndexStatus));

      const keyIndex = orderStatusToKeyIndexMap[lowestIndexStatus] || 0;
      setActiveKey(keyIndex);

      const label = statusLabelMap.get(lowestIndexStatus);
      setOrderStatusLabel(label);
    }
  }, [agreements]);

  return { orderStatus, statusAlert, contractNumbers, orderStatusLabel, activeKey };
};

export default useOrderStatus;
