import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import '../order-tracker.scss';
import { fetchProofStatuses } from '../../../redux/slices/adPortal/proofSlice';
import ProofReady from './ProofReady';
import ProofRevisionNeeded from './ProofRevisionNeeded';

const Proofing = () => {
  const { agreementID } = useParams();
  const dispatch = useDispatch();

  const { statuses } = useSelector((state) => state.proofStatus);
  const [allRejected, setAllRejected] = useState(false);
  const [allApproved, setAllApproved] = useState(false);

  useEffect(() => {
    if (agreementID) {
      dispatch(fetchProofStatuses(agreementID));
    }
  }, [agreementID, dispatch]);


  useEffect(() => {
    setAllRejected(statuses && statuses.length > 0 && statuses.every(proof => proof.Status === 'Rejected'));
    setAllApproved(statuses && statuses.length > 0 && statuses.every(proof => proof.Status === 'Approved'));
  }, [statuses]); 

  return (
    <>        
      {statuses.length && allRejected ?
        <ProofRevisionNeeded /> :
        <ProofReady />
      }       
       
    </>
  );
};

export default Proofing;
