export const handleScroll = () => {
  const nowPlayingHeader = document.getElementById('now-playing-header');
  const scheduledHeader = document.getElementById('scheduled-header');

  const nowPlayingSection = document.getElementById('now-playing-section');
  const scheduledSection = document.getElementById('coming-soon-section');

  // Get the height of the header
  const headerHeight = document.querySelector('.scrolling-text-header').offsetHeight;

  // Get the positions, but subtract the header height
  let nowPlayingPosition;
  let scheduledPosition;

  nowPlayingSection ?
    nowPlayingPosition = nowPlayingSection.getBoundingClientRect().top - headerHeight :
    nowPlayingPosition = null;

  scheduledSection ?
    scheduledPosition = scheduledSection.getBoundingClientRect().top - headerHeight :
    scheduledPosition = null;

  if (scheduledPosition && scheduledPosition <= 95) {
    nowPlayingHeader.style.display = 'none';
    scheduledHeader.style.display = 'block';

  } else if (nowPlayingPosition && nowPlayingPosition <= 95) {
    if (scheduledPosition) {
      nowPlayingHeader.style.display = 'block';
      scheduledHeader.style.display = 'none';
    }
  }
};



