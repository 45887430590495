import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import GymImage from '../../images/gym_logo.png';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { requestPasswordReset } from '../../redux/slices/userSlice'; 

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const { resetStatus, passwordResetStatus } = useSelector(state => state.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(requestPasswordReset(email));
   
  };

  return (

    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-8 col-lg-7 col-xl-6 align-bottom text-center">
            <img
              alt="MyGymTV Logo image"
              className="img-fluid my-5 w-75 text-center mx-auto"
              src={GymImage}
            />
          </div>
          <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 mt-2">
          <h4 className="mb-4">Enter your email and a link to reset your password will be sent to you.</h4>
            <form onSubmit={handleSubmit}>
              <div className="form-outline mb-4">
                <input
                  autoComplete="email"
                  className="form-control form-control-lg"
                  id="form1Example1"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  value={email}
                />
                <label
                  className="form-label"
                  htmlFor="form1Example1"
                >
                  Email address
                </label>
              </div>
              <div className="w-100">
                <Button
                  className="btn-lg w-100"
                  type="submit"
                  variant="primary"
                >
                  Send Reset Link
                </Button>
              </div>
              <div className="my-4">
                {passwordResetStatus && <p>{passwordResetStatus}</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PasswordResetRequest;
