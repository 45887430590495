import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import GymImage from '../../images/gym_logo.png';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { createPasswordForNewAdvertiser } from '../../redux/slices/userSlice';

const UpdatePassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [captchaValue, setCaptchaValue] = useState('');
  const [userCaptchaInput, setUserCaptchaInput] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryString = location.search;
  const searchParams = new URLSearchParams(queryString);
  const username = searchParams.get('username');
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    if (userCaptchaInput !== captchaValue) {
      alert('Incorrect CAPTCHA, please try again.');
      return;
    }

    const userDetails = {
      email: username,
      newPassword: password,
    };

    try {
      const response = await dispatch(createPasswordForNewAdvertiser(userDetails));
      if (response.payload) {
        setShowModal(true);
      }
    } catch (error) {
      console.log('it dies here');
    }

    setEmail('');
    setPassword('');
  };

  const handleGoToLogin = () => {
    navigate('/login');
  }

  useEffect(() => {
    const generatedCaptcha = Math.floor(1000 + Math.random() * 9000).toString();
    setCaptchaValue(generatedCaptcha);
  }, []);


  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-8 col-lg-7 col-xl-6 align-bottom text-center">
            <img
              alt="Phone image"
              className="img-fluid my-5 w-75 text-center mx-auto"
              src={GymImage}
            />
          </div>
          <h4 className="col-md-8 mb-4 text-center">
            Welcome to MyGymTV! Please create a new password to access your account.
          </h4>
          <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 mt-2 mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="form-outline mb-4">
                <input
                  autoComplete="email"
                  className="form-control form-control-lg"
                  disabled
                  id="form1Example1"
                  name="email"
                  onChange={(e) => setEmail(username)}
                  readOnly
                  type="email"
                  value={username}
                />
                <label
                  className="form-label"
                  htmlFor="form1Example1"
                >
                  Email address
                </label>
              </div>
              <div className="form-outline mb-4">
                <input
                  autoComplete="current-password"
                  className="form-control form-control-lg"
                  id="form1Example2"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  value={password}
                />
                <label
                  className="form-label"
                  htmlFor="form1Example2"
                >
                  Password
                </label>
              </div>
              <div className="form-outline mb-4">
                <input
                  autoComplete="new-password"
                  className="form-control form-control-lg"
                  id="form1Example3"
                  name="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  value={confirmPassword}
                />
                <label
                  className="form-label"
                  htmlFor="form1Example3"
                >Confirm Password</label>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label">CAPTCHA: {captchaValue}</label>
                <input
                  className="form-control form-control-lg"
                  onChange={(e) => setUserCaptchaInput(e.target.value)}
                  placeholder="Type the numbers you see"
                  type="text"
                  value={userCaptchaInput}
                />
              </div>

              <div className="w-100">
                <Button
                  className="btn-lg w-100"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        onHide={handleGoToLogin}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon
              className="text-whtie"
              icon={faCircleExclamation}
              size="2xl"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ms-2 text-success alert alert-success">Success! Click 'Login' below to get started.</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleGoToLogin}
            variant="secondary"
          >
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </section>

  );
};

export default UpdatePassword;
