import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faDumbbell } from '@fortawesome/free-solid-svg-icons';
import '../order-tracker.scss';

const SoftwareInfo = () => {
  const { initialLoginData } = useSelector((state) => state.user);
  const account = initialLoginData?.Account;

  return (
    <>
      {account ? (
        <Card className="m-2 col-lg-5 col-md-10 col-sm-10 border-0 mx-auto">
          <Card.Header className="account-card-header d-flex justify-content-between align-items-center">
            <div>
              MyGymTV
            </div>
            <FontAwesomeIcon
              className="account-icon"
              icon={faCircleInfo}
            />
          </Card.Header>
          <Card.Body className="order-status p-2">
              <div className="row mb-1">
                <div className="col-4"><strong>Software Version:</strong></div>
                <div className="col">1.3.0</div>
              </div>
              <div className="row mb-1">
                <div className="col-4"><strong>Last Updated:</strong></div>
                <div className="col">5/28/2024</div>
              </div>
              {/*<div className="row mb-1">*/}
              {/*  <div className="col-4"><strong>Change Log:</strong></div>*/}
              {/*  <div className="col"><Button>View</Button></div>*/}
              {/*</div>*/}
          </Card.Body>
        </Card>
      ) :
        <FontAwesomeIcon
          className="text-secondary"
          icon={faDumbbell}
          size="2xl"
          spin
          style={{ marginTop: '150px' }}
        />
      }
    </>

  );
};

export default SoftwareInfo;
