import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHour, setMeridian } from '../../../../redux/slices/myGymTV/timeSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const IterateTime = () => {
  const dispatch = useDispatch();
  const { hour, meridian } = useSelector((state) => state.time);

  const updateHourMeridian = (newHour) => {
    let newMeridian = meridian;

    if (newHour === 12) {
      newMeridian = meridian === 'AM' ? 'PM' : 'AM';
    } else if (newHour > 12) {
      newHour = newHour % 12;
    } else if (newHour < 1) {
      newHour = 12 + newHour;
      newMeridian = meridian === 'AM' ? 'PM' : 'AM';
    }

    dispatch(setHour(newHour));
    dispatch(setMeridian(newMeridian));
  };

  const incrementTime = () => {
    updateHourMeridian(hour + 1);
  };

  const decrementTime = () => {
    updateHourMeridian(hour - 1);
  };

  return (
    <div
    className="time-dial"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}
    >
      <FontAwesomeIcon
        icon={faCaretUp}
        onClick={incrementTime}
        size="xl"
      />
      <div
      className="text-center mx-auto time-dial-time"
        style={{width: '100px',cursor: 'grab', textAlign: 'center' }}
      >
        {`${hour} ${meridian}`}
      </div>
      <FontAwesomeIcon
        icon={faCaretDown}
        onClick={decrementTime}
        size="xl"
      />
    </div>
  );
};

export default IterateTime;
