import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import VenueSelect from '../Navigation/VenueSelect.js';

const Welcome = () => {

  const { initialLoginData } = useSelector((state) => state.user);

  return (
    <>
      <Card
        className="mt-5 p-3 text-center"
      >
        <Card.Title>
          Welcome to MyGymTV!
        </Card.Title>
        <Card.Body>
            {initialLoginData && initialLoginData.InitialLoginData && initialLoginData.InitialLoginData.length > 0 ?
              <div>
                Please select a Venue to continue.
              </div> :
              <div>
                <p>
                  You don't have any venues assigned to your account.
                </p>
                <p>
                  Please contact <a href="mailto:training@clubcom.com">training@clubcom.com</a> for assistance.
                </p>
              </div>
            }

          {initialLoginData && initialLoginData.InitialLoginData && initialLoginData.InitialLoginData.length > 0 ?
            <VenueSelect />
            : null
          }
        </Card.Body>
      </Card>
    </>



  )
};

export default Welcome;
