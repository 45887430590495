import React, { useState, useEffect } from 'react';
import Layout from '../../../../layout/Layout';
import DatePicker from 'react-datepicker';
import { Card, Form, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmarkCircle, faCalendar } from '@fortawesome/free-solid-svg-icons';
import './scrolling-text.scss';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  saveScrollingMessages,
  getScrollingMessages
} from '../../../../redux/slices/myGymTV/scrollingMessagesSlice.js';
import { v4 as uuidv4 } from 'uuid';

const CreateScrollingText = () => {
  const { id } = useParams();
  const text = useSelector((state) => {
    const scrollMessages = state.scrollingText?.scrollingMessages;

    if (scrollMessages) {
      const foundMessage = scrollMessages.find((message) => message.Sequence === parseInt(id));
      return foundMessage ? foundMessage : null;
    }
    return null;
  });

  const [messages, setMessages] = useState([{ id: uuidv4(), message: '', startDate: '', endDate: '' }]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const MESSAGE_MAX_LENGTH = 255;

  useEffect(() => {
    if (text) {
      setMessages([{
        id: text.Sequence,
        message: text.Message,
        startDate: new Date(text.StartDate),
        endDate: new Date(text.EndDate)
      }]);
    }
  }, [text, id])

  const handleAddMessage = () => {
    setMessages([...messages, { id: uuidv4(), message: '', startDate: '', endDate: '' }]);
  }

  const handleRemoveMessage = (id) => {
    setMessages(messages.filter(message => message.id !== id));
  }

  const handleInputChange = (id, field, value) => {
    setMessages(messages.map(message => message.id === id ? { ...message, [field]: value } : message));
  };

  let scrollMessages = [];

  const handleAddScrollingText = async () => {
    if (!text) {
      console.log('no text')
      console.log('nt-mesg', messages)
      scrollMessages = messages.map((message, index) => ({
      Message: message.message,
      StartDate: message.startDate && message.startDate !== '0001-01-01T00:00:00' 
        ? message.startDate.toISOString() 
        : null,
      EndDate: message.endDate && message.endDate !== '0001-01-01T00:00:00'
        ? message.endDate.toISOString() 
        : null,
      Sequence: null,
      active: true
      }));
    } else {
      console.log('text')
      scrollMessages = messages.map((message, index) => ({
        Message: message.message,
        StartDate: message.startDate && message.startDate !== '0001-01-01T00:00:00'
          ? message.startDate.toISOString()
          : null,
        EndDate: message.endDate && message.endDate !== '0001-01-01T00:00:00'
          ? message.endDate.toISOString()
          : null,
        Sequence: message.id,
        active: true
      }));     
    }
    try {
      const saveResult = await dispatch(saveScrollingMessages(scrollMessages));
      unwrapResult(saveResult);

      const fetchResult = await dispatch(getScrollingMessages());
      unwrapResult(fetchResult);

      navigate('/scrolling-text');
    } catch (error) {
      console.log(scrollMessages)
      console.error('An error occurred:', error);
    }
  };

  return (
    <Layout>
      <Card>
        <Card.Header as="h5">{ text && 'Edit' } Scrolling Text</Card.Header>
        <Card.Body>
          {messages.map(({ id, message, startDate, endDate }, index) => (
            <div
              className="border py-3 my-3 rounded-3"
              key={id}
            >
              <div className="input-group px-1 py-2">
                <textarea
                  aria-describedby="inputGroup-sizing-default"
                  aria-label="Sizing example input"
                  className="form-control"
                  onChange={(e) => handleInputChange(id, 'message', e.target.value)}
                  rows="5"
                  value={message}
                />

                <span
                  className="input-group-text"
                  id="inputGroup-sizing-default"
                >
                  {message.length}/{MESSAGE_MAX_LENGTH}
                </span>

                <Form.Group
                  className="mb-3 w-100"
                  controlId="formBasicInput"
                >
                  <Form.Label>Date Range</Form.Label>
                  <div className="d-flex rounded mb-2 w-100 btn btn-primary">
                    <FontAwesomeIcon
                      className="account-settings"
                      icon={faCalendar}
                    />
                    <DatePicker
                      className="btn btn-primary"
                      onChange={(date) => handleInputChange(id, 'startDate', date)}
                      placeholderText="Start"
                      popperPlacement="top-start"
                      selected={startDate}
                      style={{zIndex: '2'}}
                    />
                  </div>
                  <div className="d-flex rounded w-100 btn btn-primary">
                    <FontAwesomeIcon
                      className="account-settings"
                      icon={faCalendar}
                    />
                    <DatePicker
                      className="btn btn-primary"
                      onChange={(date) => handleInputChange(id, 'endDate', date)}
                      placeholderText="End"
                      selected={endDate}
                    />
                  </div>

                </Form.Group>
              </div>
              {message &&
                <div className="marquee px-1">
                  <span className="fs-3">{message}</span>
                </div>
              }

              {index !== 0 && (
                <div className="d-flex justify-content-end align-items-center pe-2">
                  <FontAwesomeIcon
                    className="text-muted"
                    icon={faXmarkCircle}
                    onClick={() => handleRemoveMessage(id)}
                  />
                  <div className="text-muted ps-2">
                    Remove
                  </div>
                </div>
              )}
            </div>
          ))}

            {!text &&
            <div className="d-flex align-items-center p-3">
              <FontAwesomeIcon
                icon={faPlus}
                onClick={() => handleAddMessage()}
              />

              <div className="text-muted ps-2">
                Add another message
              </div>
          </div>
            }

          <div className="d-flex text-center text-muted justify-content-around fs-xs px-1">
            <Button
              className="py-1 btn btn-primary"
              disabled={!messages.some(msg => msg.message)}
              onClick={handleAddScrollingText}
            >
              Save
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Layout>
  );
};



export default CreateScrollingText;
