export const determineTypeFromUrl = (url, defaultType) => {
  if (url.includes('/NS/') || url.includes('/SS/')) {
    return 'GymTV Ad';
  } else if (url.includes('/MOBILE/')) {
    return 'Mobile Ad';
  } else if (url.includes('Syndicated')) {
    return 'SYNDICATED AD';
  } else if (url.includes('/CARDIO/')) {
    return 'CARDIO AD (IN THE SS URL)';
  }
  return defaultType;
}
