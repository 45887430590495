import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout/Layout';
import { Button, Form, Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { uploadImage, editContent } from '../../../../redux/slices/myGymTV/signageSlice';

const UploadSignage = () => {
  const navigate = useNavigate();
  const contentID = useParams();

  const content = useSelector((state) => {
    const signages = state.signage?.marketingOrderContent;
    if (signages) {
      const foundContent = signages.find((content) => {
        return content.ContentID === parseInt(contentID.id);
      });
      return foundContent ? foundContent : null;
    }
    return null;
  });


  const [selectedFile, setSelectedFile] = useState(null);
  const [videoDuration, setVideoDuration] = useState(null);
  const [contentTypeID, setContentTypeID] = useState(null);
  const [displaySeconds, setDisplaySeconds] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const user = useSelector((state) => state.user) || [];
  const signage = useSelector((state) => state.signage.marketingOrderContent) || [];
  const [editSignage, setEditSignage] = useState([]);

  useEffect(() => {
    if (content) {
      setTitle(content.ContentName);
      setStartDate(new Date(content.StartDate));
      setEndDate(new Date(content.EndDate));
      setEditSignage([{
        contentID: content.ContentID,
        title: content.ContentName,
        startDate: new Date(content.StartDate),
        endDate: new Date(content.EndDate)
      }]);
    }
  }, [content, contentID])


  function toSafeInteger(num) {
    if (Number.isSafeInteger(num)) {
      return num;
    }
    return Math.min(Math.max(Math.round(num), Number.MIN_SAFE_INTEGER), Number.MAX_SAFE_INTEGER);
  }



  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const allowedImageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

    if (!allowedImageTypes.includes(file.type)) {
      alert('Please upload a valid image format: PNG, JPG, JPEG, or GIF.');
      event.target.value = '';
    } else {

      setSelectedFile(file);
      const reader = new FileReader();

      reader.onload = () => {
        const isVideo = file.type.startsWith('video');

        if (!isVideo) {
          setMediaType(1)
          setDisplaySeconds(15);
          setContentTypeID(3);
          setPreviewImage(reader.result);
          const image = new Image();
          image.onload = () => {
            setHeight(image.height);
            setWidth(image.width);
          };
          image.src = reader.result;
        }

        if (isVideo) {
          setContentTypeID(2);
          const video = document.createElement('video');
          video.preload = 'metadata';

          setMediaType(3);

          video.onloadedmetadata = () => {
            setDisplaySeconds(toSafeInteger(video.duration));
            // Capture a frame to create a thumbnail
            video.currentTime = video.duration / 3; // capturing at 1/3 duration
          };

          video.onseeked = () => {
            const canvas = document.createElement('canvas');
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const thumbnail = canvas.toDataURL('image/jpeg');
            setPreviewImage(thumbnail); // setting the thumbnail as preview
            setHeight(canvas.height);
            setWidth(canvas.width);
          };

          video.src = URL.createObjectURL(file);
        }
      };

      reader.readAsDataURL(file);

    }

  };




  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handlestartDateChange = (date) => {
    setStartDate(date);
  };

  const handleendDateChange = (date) => {
    setEndDate(date);
  };

  let newRecordXml = {};

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('name', title);
    formData.append('user', user.user.Email);
    formData.append('contentTypeID', contentTypeID);
    formData.append('venueID', user.selectedVenue.EntityID);
    formData.append('orderID', signage[0].orderID);

    if (!editSignage.length) {
      var digitalSignageImageModel = {
        createdBy: user.user.Email,
        name: title,
        type: 1,
        fileName: selectedFile.name,
        fileSize: selectedFile.size,
        height: height,
        width: width,
        venueID: user.selectedVenue.EntityID,
        startDate: startDate,
        endDate: endDate,
        IsActive: true,
        displaySeconds: displaySeconds
      };

      newRecordXml = {
        ContentID: 'contentID_placeholder',
        isActive: true,
        startDate: startDate,
        endDate: endDate,
        displaySeconds: displaySeconds,
        contentTypeID: contentTypeID
      };

    } else {

      newRecordXml = {
        ContentID: editSignage[0].contentID,
        isActive: true,
        startDate: startDate && startDate.toISOString() || null,
        endDate: endDate && endDate.toISOString() || null,
        displaySeconds: 15,
        contentTypeID: 3
      };

    }


    let existingRecordsXml;
    let xmlContent;

    if (signage[0].ContentID !== null) {
      existingRecordsXml = signage.map((record) => ({
        ContentID: record.ContentID,
        isActive: record.IsActive,
        startDate: record.StartDate,
        endDate: record.EndDate,
        displaySeconds: record.DisplaySeconds,
        contentTypeID: record.ContentType == 'I' ? 3 : 2
      }));
    }

    let recordExists = false;
    if (existingRecordsXml) {
      existingRecordsXml = existingRecordsXml.map(record => {
        if (record.ContentID === newRecordXml.ContentID) {
          recordExists = true;
          return {
            ...record,
            startDate: newRecordXml.startDate,
            endDate: newRecordXml.endDate
          };
        }
        return record;
      });
    }

    // If the record doesn't exist, append it
    if (!recordExists) {
      xmlContent = existingRecordsXml ? [...existingRecordsXml, newRecordXml] : [newRecordXml];
    } else {
      xmlContent = existingRecordsXml;
    }

    // Convert to JSON string to pass as FormData
    formData.append('digitalSignageImageModel', JSON.stringify(digitalSignageImageModel));
    if (!editSignage.length) {
      dispatch(uploadImage({
        formData: formData,
        orderID: signage[0].orderID,
        xmlContent: xmlContent
      })).then((action) => {
        if (action.type.endsWith('fulfilled')) {
          // Reset the form
          setSelectedFile(null);
          setWidth(null);
          setHeight(null);
          setPreviewImage(null);
          setTitle('');
          setStartDate(null);
          setEndDate(null);
          navigate('/digital-signage');
        } else {
          console.error('Error uploading signage:', action);
        }
      });
    } else {
      dispatch(editContent({
        formData: formData,
        orderID: signage[0].orderID,
        xmlContent: xmlContent
      })).then((action) => {
        if (action.type.endsWith('fulfilled')) {
          // Reset the form
          setSelectedFile(null);
          setWidth(null);
          setHeight(null);
          setPreviewImage(null);
          setTitle('');
          setStartDate(null);
          setEndDate(null);
          navigate('/digital-signage');
        } else {
          console.error('Error uploading signage:', action);
        }
      });
    }

  };


  const isDateInRange = (date) => {
    return startDate <= date && date <= endDate;
  };

  const isFutureDate = (date) => {
    return date > new Date();
  };

  const isPastDate = (date) => {
    return date < new Date();
  };

  const isFormValid = !editSignage.length ? selectedFile && title : true;

  return (
    <Layout>
      <Card>
        <Card.Header as="h5">{editSignage.length ? 'Edit Signage' : 'Upload Signage'}</Card.Header>
        <Card.Body>
          <Form className="upload">
            {!editSignage.length ?
              <>
                <Form.Group
                  className="mb-3"
                  controlId="formPreviewImage"
                >
                  {previewImage && (
                    <div className="text-center">
                      <img
                        alt="Selected Signage"
                        className="rounded"
                        src={previewImage}
                      />
                    </div>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="formFileUpload"
                >
                  <Form.Control
                    accept=".png, .jpg, .jpeg, .gif"
                    name="image"
                    onChange={handleFileSelect}
                    type="file"
                  />

                </Form.Group>
              </> : null
            }

            <Form.Group
              className="mb-3"
              controlId="formBasicInput"
            >
              <Form.Label>Title</Form.Label>
              
              <Form.Control
                disabled={editSignage.length}
                onChange={handleTitleChange}
                placeholder="Enter Title"
                type="text"
                value={title}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formBasicInput"
            >
              <Form.Label>Date Range</Form.Label>
              <Button className="d-flex rounded mb-2 w-100">
                <FontAwesomeIcon
                  className="account-settings"
                  icon={faCalendar}
                  onClick={() => navigate('/digital-signage')}
                />
                <DatePicker
                  className="btn btn-primary w-50"
                  onChange={handlestartDateChange}
                  placeholderText="Start"
                  selected={startDate}
                />
              </Button>
              <Button className="d-flex rounded w-100">
                <FontAwesomeIcon
                  className="account-settings"
                  icon={faCalendar}
                  onClick={() => navigate('/digital-signage')}
                />
                <DatePicker
                  className="btn btn-primary"
                  onChange={handleendDateChange}
                  placeholderText="End"
                  selected={endDate}
                />
              </Button>
            </Form.Group>
            <Button
              disabled={!isFormValid}
              onClick={handleUpload}
            >
              {editSignage.length ? 'Save' : 'Upload'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Layout>
  );
};

export default UploadSignage;
