import React, { useState, useEffect } from 'react';
import DesktopNav from './DesktopNav';
import TapBar from './TapBar';
import { useMediaQuery } from 'react-responsive';

const Nav = () => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 1023px)',
  });

  return isMobile ? <TapBar /> : <DesktopNav />;
};

export default Nav;
