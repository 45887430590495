import React from 'react';
import Layout from '../../../layout/Layout';
import AccountInfo from './AccountInfo';
import SupportInfo from './SupportInfo';
import AgreementInfo from './AgreementInfo';
import SoftwareInfo from './SoftwareInfo';

const AccountPage = () => {

  return (
    <Layout>
      <h5 className="text-center pt-2">Your Account</h5>
      <AccountInfo />
      <SupportInfo />
      <AgreementInfo />
      <SoftwareInfo />
    </Layout>
  );
};

export default AccountPage;
