import { createSlice } from '@reduxjs/toolkit';

const getCurrentHour24 = () => {
  const date = new Date();
  return date.getHours();
};

const getMeridian = (hour24) => {
  return hour24 >= 12 ? 'PM' : 'AM';
};

const getAdjustedHour = (hour24) => {
  return hour24 > 12 ? hour24 - 12 : (hour24 === 0 ? 12 : hour24);
};

const currentHour24 = getCurrentHour24();
const currentMeridian = getMeridian(currentHour24);
const adjustedHour = getAdjustedHour(currentHour24);

export const timeSlice = createSlice({
  name: 'time',
  initialState: {
    hour: adjustedHour,
    meridian: currentMeridian,
    hour24Format: currentHour24
  },
  reducers: {
    setHour: (state, action) => {
      state.hour = action.payload;
      if (state.meridian === 'PM' && action.payload !== 12) {
        state.hour24Format = action.payload + 12;
      } else if (state.meridian === 'PM' && action.payload === 12) {
        state.hour24Format = 12;
      } else if (state.meridian === 'AM' && action.payload !== 12) {
        state.hour24Format = action.payload;
      } else {
        state.hour24Format = 0;
      }
    },
    setMeridian: (state, action) => {
      state.meridian = action.payload;
      if (action.payload === 'PM' && state.hour !== 12) {
        state.hour24Format = state.hour + 12;
      } else if (action.payload === 'PM' && state.hour === 12) {
        state.hour24Format = 12;
      } else if (action.payload === 'AM' && state.hour !== 12) {
        state.hour24Format = state.hour;
      } else {
        state.hour24Format = 0;
      }
    }
  }
});

export const { setHour, setMeridian } = timeSlice.actions;

export default timeSlice.reducer;
