import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Modal, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateDaySchedule } from '../../../../redux/slices/myGymTV/entertainmentSlice.js';
import './entertainment.scss';
import {
  getScheduleFromPreferences,
  getScheduleByDayAndHour,
  getDaySchedule
} from '../../../../helpers/transformations';

const CopyDay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { preferences, selectedDay, selectedHour, selectedChannel, currentChannel } = useSelector((state) => state.entertainment);
  const channelSchedule = getScheduleFromPreferences(preferences);
  const currentSchedule = getScheduleByDayAndHour(channelSchedule, currentChannel);
  const daySchedule = getDaySchedule(channelSchedule,selectedDay.label)
  const [selectedDays, setSelectedDays] = useState([]);

  useEffect(() => {
    selectedDays.length && console.log(selectedDays[0].name)
  }, [selectedDays])


  const daysOfWeek = [
    { id: 1, name: 'Sunday', disabled: selectedDay.label === 'Sunday', checked: false },
    { id: 2, name: 'Monday', disabled: selectedDay.label === 'Monday', checked: false },
    { id: 3, name: 'Tuesday', disabled: selectedDay.label === 'Tuesday', checked: false },
    { id: 4, name: 'Wednesday', disabled: selectedDay.label === 'Wednesday', checked: false },
    { id: 5, name: 'Thursday', disabled: selectedDay.label === 'Thursday', checked: false },
    { id: 6, name: 'Friday', disabled: selectedDay.label === 'Friday', checked: false },
    { id: 7, name: 'Saturday', disabled: selectedDay.label === 'Saturday', checked: false },
  ];

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);


  const handleSelectDay = () => {
    setSelectedDays([]);
    daysOfWeek
      .filter((day) => document.getElementById(day.name).checked)
      .map((day) => {
        setSelectedDays(prevSelectedDays => [...prevSelectedDays,
        {
          name: day.name,
          data: day.id
          }])
      });
  };
  const handleCopyToDays = () => {
    const selectedDaysData = selectedDays.map(day => day.data);
    dispatch(updateDaySchedule({ selectedDays: selectedDaysData, daySchedules: daySchedule }));
    setShowConfirmationModal(false);
  }

  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  let currentDate = new Date();
  let currentDay = currentDate.getDay();
  currentDay = (currentDay === 0) ? 7 : currentDay;

  return (
    <Card
      className="border-0"
      id="copy-day"
    >
      {Object.keys(selectedDay).length > 0 &&

        <Card.Body>

          <Card.Title className="text-secondary py-2">Copy {selectedDay.label}'s Schedule To:</Card.Title>
          <ListGroup>
            {
              daysOfWeek.map(day => (
                <ListGroupItem
                  className="ps-5 d-flex align-items-center form-check form-switch"
                  key={day.id}
                >
                  <input
                    className="me-4 form-check-input"
                    disabled={day.disabled}
                    id={day.name}
                    onChange={() => { handleSelectDay(selectedDay) }}
                    type="checkbox"
                  />
                  <label
                    className=" mb-0 form-check-label"
                    htmlFor={day.name}
                  >
                    {day.name}
                  </label>
                </ListGroupItem>
              ))
            }
          </ListGroup>

          <Button
            className="w-100 mt-4"
            disabled={!selectedDays.length}
            onClick={openConfirmationModal}
          >
            Copy Schedule
          </Button>
          <Modal
            onHide={closeConfirmationModal}
            show={showConfirmationModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to copy {selectedDay.label}'s schedule to the following days?

              <ul>
                {selectedDays.length && selectedDays.map((day) =>
                  <li
                    className="pt-2"
                    key={day.name}
                  >{day.name}</li>
                )}
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={closeConfirmationModal}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleCopyToDays(selectedDays)}
                variant="primary"
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      }
    </Card>
  );
};

export default CopyDay;
