import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Alert, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import '../order-tracker.scss';
import { normalizeStatus } from '../../../helpers/statusNormalizer';
import useOrderStatus from '../../../hooks/useOrderStatus';

const AgreementInfo = () => {
  const { initialLoginData } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const agreements = initialLoginData.Agreements;  
  const [targetDateString, setTargetDateString] = useState('');
  const { orderStatusLabel, statusAlert } = useOrderStatus();

  const handleManageAgreementStatus = (agreementID) => {
    navigate(`/order-status/${agreementID}`);
  }

  useEffect(() => {
    const status = [];
    if (agreements) {
      for (const agreement of agreements) {
        for (const contract of agreement.Contracts) {
          const normalizedStatus = normalizeStatus(contract.WebAdStatusDisplayName ? contract.WebAdStatusDisplayName : contract.AdStatus);
          status.push(normalizedStatus);
        }
      }

      let targetDates = agreements[0].Contracts.map(contract => contract.TargetBroadcastDate);
      let targetDate = moment(targetDates[0]);
      setTargetDateString(targetDate.format('MM/DD/YYYY'));
    }
  }, [initialLoginData, statusAlert]);

  return (
    <>
      {initialLoginData && agreements && Array.isArray(agreements) && agreements.length > 0
        ? (
          <Card className="m-2 col-lg-5 col-md-10 col-sm-10 border-0 mx-auto">
            <Card.Header className="account-card-header d-flex justify-content-between align-items-center">
              <div>
                Orders
              </div>
              <FontAwesomeIcon
                className="account-icon"
                icon={faFileSignature}
              />
            </Card.Header>
            <Card.Body className="order-status p-2">

              {agreements.map((agreement, index) => (
                <div
                  key={index}
                  onClick={() => handleManageAgreementStatus(agreement.AgreementID)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div>Order ID: {agreement.AgreementID}</div>
                      <div>Target Date: {targetDateString} </div>
                      <div>Status: {orderStatusLabel} </div>
                    </div>
                    <Button>View</Button>
                  </div>
                </div>
              ))}
            </Card.Body>
          </Card>
        )
        : <p>No agreements available or data is loading.</p>
      }
    </>
  );
};

export default AgreementInfo;
