import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, Form, ProgressBar, Modal } from 'react-bootstrap';
import axios from 'axios';
import { getInitialLoginData } from '../../../redux/slices/userSlice';
import '../order-tracker.scss';
import StepTracker from './StepTracker';

const WaitingOnAssets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { initialLoginData } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  const account = initialLoginData?.Account;
  const agreements = initialLoginData?.Agreements;
  const { agreementID } = useParams();
  const advertiserCode = account?.AdvertiserCode;
  const fileInputRef = useRef();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [fileProgress, setFileProgress] = useState({});
  const [formData, setFormData] = useState({
    productDescription: '',
    slogan: '',
    contactInfo: '',
    businessName: '',
    socialMedia: '',
    certificationNumbers: '',
    specialOffers: '',
    productionNotes: ''
  });

  const handleFileUpload = async (file) => {
    console.log(file)
    const formData = new FormData();
    formData.append('file', file);
    formData.append('advertiserCode', advertiserCode);

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/AdPortalUpload/UploadFile`, formData, {
        onUploadProgress: progressEvent => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setFileProgress(prevProgress => ({ ...prevProgress, [file.name]: progress }));
        }
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    Array.from(files).forEach(file => handleFileUpload(file));
  };

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    const token = localStorage.getItem('token');
    event.preventDefault();

    // Creating an array of arrays from formData
    const jsonformData = [
      {
        'index': 1,
        'question': 'Product Description',
        'answer': formData.productDescription
      },
      {
        'index': 2,
        'question': 'Slogan',
        'answer': formData.slogan
      },
      {
        'index': 3,
        'question': 'Contact Info',
        'answer': formData.contactInfo
      },
      {
        'index': 4,
        'question': 'Business Name',
        'answer': formData.businessName
      },
      {
        'index': 5,
        'question': 'Social Media',
        'answer': formData.socialMedia
      },
      {
        'index': 6,
        'question': 'Certification Numbers',
        'answer': formData.certificationNumbers
      },
      {
        'index': 7,
        'question': 'Special Offers',
        'answer': formData.specialOffers
      },
      {
        'index': 8,
        'question': 'Production Notes',
        'answer': formData.productionNotes
      }
    ]

    let textData = jsonformData.map(item =>
      `${item.question}\n${item.answer}\n\n`
    ).join('');

    const textBlob = new Blob([textData], { type: 'text/plain' });

    // Converting the array of arrays to a JSON string
    const jsonString = JSON.stringify(jsonformData);

    const textFormData = new FormData();
    textFormData.append('file', textBlob, `${advertiserCode}.txt`);
    textFormData.append('advertiserCode', advertiserCode);

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/AdPortalUpload/UploadFile`, textFormData);
      setShowSuccessModal(true);
      clearFormData();

      // After successful upload, submit Ad Tracker Update
      await submitAdTrackerUpdate(jsonString);
    } catch (error) {
      console.error('Error uploading form data:', error);
    }
  };


  const submitAdTrackerUpdate = async (jsonString) => {
    const token = localStorage.getItem('token');
    const workQueueItemData = {
      TypeID: 3,
      AgreementID: parseInt(agreementID),
      UserID: user.UserID,
      Data: jsonString
    };

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/CRM/SubmitAdTrackerUpdate`, workQueueItemData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      /*      dispatch(getInitialLoginData(user.UserID))*/
      console.log('Ad Tracker Update submitted successfully');
    } catch (error) {
      console.error('Error submitting Ad Tracker Update:', error);
    }
  };

  const clearFormData = () => {
    setFormData({
      productDescription: '',
      slogan: '',
      contactInfo: '',
      businessName: '',
      socialMedia: '',
      certificationNumbers: '',
      specialOffers: '',
      productionNotes: ''
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFileProgress({});
  };

  return (
    <>
      <div className="status-bg text-center">
        <StepTracker />
        <Card className="border-0 status-description">
          <Card.Body className="account pt-0">
            <div className="row g-0 justify-content-center">
              <Card.Title
                className="step-title text-center d-flex justify-content-center align-items-center"
              >
                Waiting on Assets
              </Card.Title>
              <Card.Text className="text-center">
                Please submit any creative and information you would like to be in your ad.
              </Card.Text>

            </div>
          </Card.Body>
        </Card>
      </div>

      <Card className="border-0">
        <Card.Body className="p-1">

          <Form
            className="text-left"
          >
            <Form.Group
              className="mb-3 px-1 py-2 text-start"
              controlId="formFile"
            >
              <Form.Control
                multiple
                onChange={handleFileChange}
                type="file"
              />
              {Object.entries(fileProgress).map(([fileName, progress]) => (
                <div
                  className="my-2"
                  key={fileName}
                >
                  <small>{fileName}</small>
                  <ProgressBar
                    label={`${progress}%`}
                    now={progress}
                  />
                </div>
              ))}
            </Form.Group>
          </Form>
          <Form
            className="text-left"
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3 text-start">
              <Card>
                <Card.Header className="account-card-header">
                  <Form.Label>Describe the products and services that you would like to promote</Form.Label>
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    as="textarea"
                    name="productDescription"
                    onChange={handleInputChange}
                    rows={3}
                    value={formData.productDescription}
                  />
                </Card.Body>
              </Card>
            </Form.Group>

            <Form.Group className="mb-3 text-start">
              <Card>
                <Card.Header className="account-card-header">
                  <Form.Label>What is your business slogan or catchphrase?</Form.Label>
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    as="textarea"
                    name="slogan"
                    onChange={handleInputChange}
                    rows={3}
                    value={formData.slogan}
                  />
                </Card.Body>
              </Card>
            </Form.Group>

            <Form.Group className="mb-3 text-start">
              <Card>
                <Card.Header className="account-card-header">
                  <Form.Label>Contact info you would like in your ad? (required)</Form.Label>
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    as="textarea"
                    name="contactInfo"
                    onChange={handleInputChange}
                    required
                    rows={3}
                    value={formData.contactInfo}
                  />
                </Card.Body>
              </Card>
            </Form.Group>

            <Form.Group className="mb-3 text-start">
              <Card>
                <Card.Header className="account-card-header">
                  <Form.Label>Business name you would like displayed in ad and pronunciation, if applicable?</Form.Label>
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    as="textarea"
                    name="businessName"
                    onChange={handleInputChange}
                    rows={3}
                    value={formData.businessName}
                  />
                </Card.Body>
              </Card>
            </Form.Group>

            <Form.Group className="mb-3 text-start">
              <Card>
                <Card.Header className="account-card-header">
                  <Form.Label>Which social media platforms you would like featured in your ad?</Form.Label>
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    as="textarea"
                    name="socialMedia"
                    onChange={handleInputChange}
                    rows={3}
                    value={formData.socialMedia}
                  />
                </Card.Body>
              </Card>
            </Form.Group>

            <Form.Group className="mb-3 text-start">
              <Card>
                <Card.Header className="account-card-header">
                  <Form.Label>List any special certification numbers, ID’s, etc. required in your advertisement.</Form.Label>
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    as="textarea"
                    name="certificationNumbers"
                    onChange={handleInputChange}
                    rows={3}
                    value={formData.certificationNumbers}
                  />
                </Card.Body>
              </Card>
            </Form.Group>

            <Form.Group className="mb-3 text-start">
              <Card>
                <Card.Header className="account-card-header">
                  <Form.Label>Are there any special offers you would like advertised?</Form.Label>
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    as="textarea"
                    name="specialOffers"
                    onChange={handleInputChange}
                    rows={3}
                    value={formData.specialOffers}
                  />
                </Card.Body>
              </Card>
            </Form.Group>

            <Form.Group className="mb-3 text-start">
              <Card>
                <Card.Header className="account-card-header">
                  <Form.Label>Production Notes/Special Instructions:</Form.Label>
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    as="textarea"
                    name="productionNotes"
                    onChange={handleInputChange}
                    rows={3}
                    value={formData.productionNotes}
                  />
                </Card.Body>
              </Card>
            </Form.Group>

            <Button
              type="submit"
              variant="primary"
            >Submit</Button>
          </Form>

          <Modal
            className='submit-success-modal'
            onHide={() => setShowSuccessModal(false)}
            show={showSuccessModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Success</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your information has been successfully uploaded! The tracker will be updated shortly.</Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => { setShowSuccessModal(false); navigate('/ad-account'); }}
                variant="secondary"
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

        </Card.Body>
      </Card >
    </>

  );
};

export default WaitingOnAssets;
