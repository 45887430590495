import React, { useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedVenue } from '../../../redux/slices/userSlice';
import { fetchEntertainmentData } from '../../../redux/slices/myGymTV/entertainmentSlice';
import { getScrollingMessages } from '../../../redux/slices/myGymTV/scrollingMessagesSlice';
import './top-nav.scss';

const VenueSelect = () => {
  const dispatch = useDispatch();
  const { selectedVenue, initialLoginData } = useSelector((state) => state.user);

  useEffect(() => {
    localStorage.setItem('selectedVenue', JSON.stringify(selectedVenue));
  }, [selectedVenue]);

  const handleSelectVenue = (venue) => {
    dispatch(setSelectedVenue(venue));
    dispatch(fetchEntertainmentData());
    dispatch(getScrollingMessages());
  };

  return (
    <>
      {

          <Dropdown className="full-width-dropdown-container">
            <Dropdown.Toggle
              id='venue-dropdown'
              variant='primary'
            >
              {
               selectedVenue  ? selectedVenue.ClubName : 'Select'
              }
            </Dropdown.Toggle>
            <Dropdown.Menu className="full-width-dropdown">
              {initialLoginData.InitialLoginData && initialLoginData.InitialLoginData.map((club, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleSelectVenue(club)}
                >
                  <div>
                    {club.ClubName}
                  </div>
                  <div className='club__name text-muted'>
                    {club.ZoneName}
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
      }
    </>
  );
};

export default VenueSelect;
