import React from 'react';
import { useSearchParams } from 'react-router-dom';
import DefaultLogin from './DefaultLogin';
import UpdatePassword from './UpdatePassword';
import useLoginRedirect from '../../hooks/useLoginRedirect';

const Login = () => {
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');

  useLoginRedirect();

  return username ? <UpdatePassword username={username} /> : <DefaultLogin />;
};

export default Login;

