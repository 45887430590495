import React, { useState, useEffect } from 'react';
import Layout from '../../../../layout/Layout';
import AvaliableChannels from './AvailableChannels';
import IterateTime from './DraggableTime';
import { useNavigate } from 'react-router-dom';
import { Card, Accordion, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedDay,
  setSelectedHour,
  setEditingChannel,
  fetchEntertainmentData
} from '../../../../redux/slices/myGymTV/entertainmentSlice.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faBan, faCopy, faGlobe, faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import './entertainment.scss';
import {
  getPreferenceByDayAndHour,
  getScheduleFromPreferences,
  getScheduleByDayAndHour,
  getEditingChannel
} from '../../../../helpers/transformations';




const Entertainment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAvailableChannels, setShowAvailableChannels] = useState(false);
  const handleCloseAvailableChannels = () => setShowAvailableChannels(false);
  const handleShowAvailableChannels = () => setShowAvailableChannels(true);
  const { selectedVenue } = useSelector((state) => state.user);
  const { hour, hour24Format } = useSelector((state) => state.time);

  const { preferences, selectedDay, selectedHour, currentChannel } = useSelector((state) => state.entertainment);
  const channelSchedule = getScheduleFromPreferences(preferences);

  const currentSchedule = getScheduleByDayAndHour(channelSchedule, currentChannel);
  let currentDate = new Date();
  let currentDay = currentDate.getDay();
  currentDay = (currentDay === 0) ? 7 : currentDay;

  useEffect(() => {
    dispatch(fetchEntertainmentData());
  }, [dispatch])

  const handleCopyDay = (dayLabel, dayData) => {
    dispatch(setSelectedDay({ label: dayLabel, data: dayData }));
    dispatch(setSelectedHour({}));
    navigate('/channel-edit/copy-day');
  }

  const handleEditSelectedHour = (dayLabel, dayData, hourData) => {
    console.log(dayLabel);
    console.log(dayData);
    console.log(hourData);
    dispatch(setSelectedHour({ label: hour24Format, data: hourData }));
    dispatch(setSelectedDay({ label: dayLabel, data: dayData }));

    const editingChannel = getEditingChannel(preferences, dayLabel, hourData);

    if (editingChannel) {
      dispatch(setEditingChannel(editingChannel));
    }

    navigate('/channel-edit/edit-channel-by-hour');
  }


  return (
    <Layout>
      <div className="entertainment-header">
        <div className="card-header">Entertainment</div>
        {selectedVenue &&
          <div
            className="card-header club__name"
            id="location-name"
          >{` -  ${selectedVenue.ZoneName}`}</div>
        }
      </div>
      <Card className="border-0">
        <Card.Body>
          <Card.Title className="text-secondary border-bottom mt-2">Quick Actions</Card.Title>
          <div className="d-flex flex-wrap pt-1">
            <div
              className="entertainment__actions-button d-flex justify-content-left align-items-center m-2"
              onClick={() => {
                navigate('/channel-edit/replace-all');
              }}
            >
              <FontAwesomeIcon
                className="entertainment__actions fa-fw ps-1"
                icon={faGlobe}
              />
              <div className="ps-2">Replace All</div>
            </div>

            <div className="entertainment__actions-button disabled d-flex justify-content-left align-items-center m-2">
              <FontAwesomeIcon
                className="entertainment__actions fa-fw ps-1"
                icon={faStar}
              />
              <div className="ps-2">Default Playlist</div>
            </div>

            <div className="entertainment__actions-button d-flex justify-content-left align-items-center m-2">
              <FontAwesomeIcon
                className="entertainment__actions fa-fw ps-1"
                icon={faBan}
                onClick={() => {
                  navigate('/channel-edit/replace-channel');
                }}
              />
              <div className="ps-1">Replace One</div>
            </div>


            <div
              className="entertainment__actions-button disabled d-flex justify-content-left align-items-center m-2"
              disabled
            >
              <FontAwesomeIcon
                className="entertainment__actions fa-fw ps-1"
                icon={faCopy}
              />
              <div className="ps-2">Copy to Venue</div>
            </div>
          </div>
        </Card.Body>
        <Card.Body>
          <Card.Title className="text-secondary border-bottom mb-3">Entertainment Schedule</Card.Title>
          <div className="entertainment-container" />
          <Accordion defaultActiveKey={(currentDay + 1).toString()}>
            {Object.keys(channelSchedule).map((day, index) => (
              <Accordion.Item
                eventKey={(index + 1).toString()}
                key={day}
              >
                <Accordion.Header>
                  {day}
                  {/*<FontAwesomeIcon*/}
                  {/*  className="fa-fw px-2 entertainment__copy-schedule"*/}
                  {/*  icon={faCopy}*/}
                  {/*  onClick={() => {*/}
                  {/*    handleCopyDay(day, channelSchedule[day]);*/}
                  {/*  }}*/}
                  {/*/>*/}
                </Accordion.Header>
                <Accordion.Body className="d-flex justify-content-left align-items-center">
                  <IterateTime />
                  <div className="d-flex justify-content-between align-items-center">
                    {
                      (() => {
                        const matchingPreference = getPreferenceByDayAndHour(preferences, index + 1, hour24Format);
                        if (matchingPreference) {
                          return (
                            <div
                              className="d-flex align-items-center"
                              onClick={() => {
                                handleEditSelectedHour(day, channelSchedule[day], channelSchedule[day][hour24Format]);
                              }}
                            >
                              {matchingPreference.WebIconPath ? 
                                <img
                                  className="entertainment-icon"
                                  src={`../../../${matchingPreference.WebIconPath}`}
                                />
                              : 
                                <img
                                  className="pe-4 entertainment__webIcon select-channel"
                                  src={'../../../images/channels/unknown.png'}
                                />
                            }

                              <div className="ms-2">
                                <div>
                                  {`Channel ${matchingPreference.ID}`}
                                </div>
                                <div>
                                  {matchingPreference.Name}
                                </div>
                              </div>
                            </ div>
                          );
                        }
                        return null; 
                      })()
                    }
                  </div>
                </Accordion.Body>

              </Accordion.Item>
            ))}
          </Accordion>

          <Modal
            className="m-0"
            onHide={handleCloseAvailableChannels}
            show={showAvailableChannels}
          >
            <Modal.Header closeButton>
              <Modal.Title>Your Channels</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AvaliableChannels closeModal={handleCloseAvailableChannels} />
            </Modal.Body>
          </Modal>
        </Card.Body>
      </Card>
    </Layout>
  );
};

export default Entertainment;
