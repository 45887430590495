import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Login from './features/Login/Login';
import UpdatePassword from './features/Login/UpdatePassword';
import Account from './features/MyGymTV/Account/Account';
import DigitalSignage from './features/MyGymTV/ContentManagement/DigitalSignage/DigitalSignage';
import ScrollingText from './features/MyGymTV/ContentManagement/ScrollingText/ScrollingText';
import Entertainment from './features/MyGymTV/ContentManagement/Entertainment/Entertainment';
import ChannelEdit from './features/MyGymTV/ContentManagement/Entertainment/ChannelEdit';
import ContentOverview from './features/MyGymTV/ContentOverview/ContentOverview';
import UploadSignage from './features/MyGymTV/ContentManagement/DigitalSignage/UploadSignage';
import CreateScrollingText from './features/MyGymTV/ContentManagement/ScrollingText/CreateScrollingText';
import OrderStatusPage from './features/AdPortal/OrderTracker/OrderStatusPage';
import AccountPage from './features/AdPortal/AccountPage/AccountPage';
import AdPortalHome from './features/AdPortal/AdPortalHome';
import Admin from './features/AdPortal/Admin/Admin';
import ProtectedRoute from './features/ProtectedRoute';
import Loading from './features/Loading';
import { getUserData } from './redux/slices/userSlice';
import { determineRedirectPath } from './helpers/redirectHandler';
import PasswordResetRequest from './features/Login/PasswordResetRequest';
import ValidateResetToken from './features/Login/ValidateResetToken';
  
const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const redirectPath = determineRedirectPath();
      console.log(window.location.pathname, redirectPath)
    if (!window.location.pathname.includes(redirectPath)) {
      console.log('redirecting')
      navigate(redirectPath);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(getUserData())
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [dispatch]);

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <Routes>
      <Route
        element={<Navigate
          replace
          to="/login"
                 />}
        path="/"
      />
      <Route
        element={<Login />}
        path="/login"
      />
      <Route
        element={<ValidateResetToken />}
        path="/validate-reset-token"
      />
      <Route
        element={<PasswordResetRequest />}
        path="/reset-password"
      />
      <Route
        element={<UpdatePassword />}
        path="/update-password"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['gymUser']}><ContentOverview /></ProtectedRoute>}
        path="/content-overview"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['gymUser']}><DigitalSignage /></ProtectedRoute>}
        path="/digital-signage"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['gymUser']}><ScrollingText /></ProtectedRoute>}
        path="/scrolling-text"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['gymUser']}><Entertainment /></ProtectedRoute>}
        path="/entertainment"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['gymUser']}><ChannelEdit /></ProtectedRoute>}
        path="/channel-edit/*"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['gymUser']}><Account /></ProtectedRoute>}
        path="/gym-account"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['gymUser']}><UploadSignage /></ProtectedRoute>}
        path="/signage-details/:id"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['gymUser']}><CreateScrollingText /></ProtectedRoute>}
        path="/scrolling-details/:id"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['gymUser']}><UploadSignage /></ProtectedRoute>}
        path="/upload-signage"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['gymUser']}><CreateScrollingText /></ProtectedRoute>}
        path="/save-scrolling-message"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['admin']}><Admin /></ProtectedRoute>}
        path="/admin"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['adUser']}><AdPortalHome /></ProtectedRoute>}
        path="/ad-portal-home"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['adUser']}><OrderStatusPage /></ProtectedRoute>}
        path="/order-status/:agreementID"
      />
      <Route
        element={<ProtectedRoute allowedRoles={['adUser', 'admin']}><AccountPage /></ProtectedRoute>}
        path="/ad-account"
      />
    </Routes>
  );
};

export default App;
