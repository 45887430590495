import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faDumbbell } from '@fortawesome/free-solid-svg-icons';
import '../order-tracker.scss';

const AccountInfo = () => {
  const { initialLoginData } = useSelector((state) => state.user);
  const advertiserAdmin  = useSelector((state) => state.advertiserAdmin);
  const account = initialLoginData?.Account;
  const advertiser = advertiserAdmin?.advertisers[0];  
  const accountEmail = advertiser?.Email;
  console.log(advertiserAdmin)
  return (
    <>
      {account ? (
        <Card className="m-2 col-lg-5 col-md-10 col-sm-10 border-0 mx-auto">
          <Card.Header className="account-card-header d-flex justify-content-between align-items-center">
            <div>
              Contact Information
            </div>
            <FontAwesomeIcon
              className="account-icon"
              icon={faCircleInfo}
            />
          </Card.Header>
          <Card.Body className="order-status p-2">
            {account.AdvertiserCode &&
              <div className="row mb-1">
                <div className="col-4"><strong>Customer Code:</strong></div>
                <div className="col">{account.AdvertiserCode}</div>
              </div>
            }
            {account.Name &&
              <div className="row mb-1">
                <div className="col-4"><strong>Advertiser:</strong></div>
                <div className="col">{account.Name}</div>
              </div>
            }
            {account.ContactName &&
              <div className="row mb-1">
                <div className="col-4"><strong>Contact:</strong></div>
                <div className="col">{account.ContactName}</div>
              </div>
            }
            {account.ContactName &&
              <div className="row mb-1">
                <div className="col-4"><strong>Email:</strong></div>
                <div className="col">{accountEmail}</div>
              </div>
            }
            {account.Address &&
              <div className="row mb-1">
                <div className="col-4"><strong>Address:</strong></div>
                <div className="col">{account.Address}</div>
              </div>
            }
            <div className="row mb-1">
              <div className="col-4" />
              <div className="col">
                {`${account.City && account.City}, 
                  ${account.State && account.State}
                  ${account.PostalCode && account.PostalCode}`}
              </div>
            </div>
            {account.Country &&
              <div className="row mb-1">
                <div className="col-4"><strong>Country:</strong></div>
                <div className="col">{account.Country}</div>
              </div>
            }
            {account.Phone &&
              <div className="row mb-1">
                <div className="col-4"><strong>Phone:</strong></div>
                <div className="col">{account.Phone}</div>
              </div>
            }
          </Card.Body>
        </Card>
      ) :
        <FontAwesomeIcon
          className="text-secondary"
          icon={faDumbbell}
          size="2xl"
          spin
          style={{ marginTop: '150px' }}
        />
    }
    </>

  );
};

export default AccountInfo;
