import { useState, useEffect, React } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMusic, faEdit, faFileSignature, faChevronLeft, faCancel, faHeadset, faImages, faMessage, faUser } from '@fortawesome/free-solid-svg-icons';
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import './mobile-nav.scss';
import { useSpring, animated } from 'react-spring';
import { useSelector } from 'react-redux';

const TapBar = () => {
  const signages = useSelector((state) => state.signage);
  const user = useSelector((state) => state.user);
  const adAccount = user.initialLoginData?.Account; // Check if initialLoginData is available
  const roles = user.user?.RoleIds || []; // Check if user data is available
  const location = useLocation();
  const validPaths = ['/digital-signage', '/scrolling-text'];

  const iconSets = {
    '/signage-details': [
      { icon: faChevronLeft, link: '/digital-signage' },
      { icon: faEdit, link: '' },
    ],
    '/upload-signage': [
      { icon: faFloppyDisk, link: '' },
      { icon: faCancel, link: '/digital-signage' },
    ],
    '/digital-signage': [{ icon: faPlus, link: '/upload-signage' }],
  };

  const [baseIcon, setBaseIcon] = useState(faPlus);
  const navigate = useNavigate();
  const [iconSet, setIconSet] = useState([]);
  const [showIcons, setShowIcons] = useState(false);
  const circleStyle = useSpring({
    height: showIcons ? `${iconSet.length * 60}px` : '65px',
    config: { mass: 1, tension: 500, friction: 18 },
  });

  useEffect(() => {
    const path = location.pathname;
    if (path in iconSets) {
      setIconSet(iconSets[path]);
    } else {
      setIconSet(iconSets.default);
    }
    setShowIcons(false);
  }, [location.pathname]);

  const handlePlusIconClick = () => {
    const path = location.pathname;

    switch (true) {
      case path === '/digital-signage':
        navigate('/upload-signage');
        break;
      case path.includes('/signage-details'):
        navigate('/digital-signage');
        break;
      case path.includes('/scrolling-text'):
        navigate('/save-scrolling-message');
        break;
      default:
        setShowIcons(true);
        break;
    }
  };

  return (
    <div className="tapbar mobile wrapper">
      <div className="navbar">
        {roles.includes(6) && (
          <FontAwesomeIcon
            className="content-overview"
            icon={faMusic}
            onClick={() => navigate('/entertainment')}
          />
        )}
        {roles.includes(1) && (
          <FontAwesomeIcon
            className="account-settings"
            icon={faImages}
            onClick={() => navigate('/digital-signage')}
          />
        )}
        {roles.includes(7) && (
          <div
className="icon-wrapper"
onClick={() => navigate('/scrolling-text')}
          >
            <FontAwesomeIcon
className="fa-icon account-settings"
icon={faMessage}
            />
            <span className="icon-text">T</span>
          </div>
        )}
        {adAccount && (
          <FontAwesomeIcon
            className="icon-settings"
            icon={faUser}
            onClick={() => navigate('/ad-account')}
          />
        )}
        {adAccount ? (
          <FontAwesomeIcon
            className="icon-settings"
            icon={faHeadset}
            onClick={() => {
              window.location.href = 'https://chat.socialintents.com/c/mygymtv';
            }}
          />
        ) : (
          <FontAwesomeIcon
            className="icon-settings"
            icon={faHeadset}
            onClick={() => {
              window.location.href = 'https://chat.socialintents.com/c/clubcom';
            }}
          />
        )}

        {validPaths.includes(location.pathname) && (
          <>
            <animated.div
className="circle"
style={circleStyle}
            >
              {showIcons ? (
                iconSets.map((item, index) => (
                  <FontAwesomeIcon
                    className="content-management"
                    icon={item.icon}
                    key={index}
                    onClick={() => navigate(item.link)}
                  />
                ))
              ) : (
                <FontAwesomeIcon
                  className="content-management"
                  icon={baseIcon}
                  onClick={handlePlusIconClick}
                />
              )}
            </animated.div>
            <div className="circleBackground" />
          </>
        )}
      </div>
    </div>
  );
};

export default TapBar;
