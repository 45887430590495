import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GymTvLogo from '../../../images/MyGymTV-white.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  faMusic,
  faHeadset,
  faImages,
  faMessage,
  faHouse,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { setSelectedVenue } from '../../../redux/slices/userSlice';
import { fetchEntertainmentData } from '../../../redux/slices/myGymTV/entertainmentSlice';
import { getScrollingMessages } from '../../../redux/slices/myGymTV/scrollingMessagesSlice';
import { getUserRoleCategories } from '../../../helpers/roleAccessControl';
import { Link } from 'react-router-dom';
import './desktop-nav.scss';
//TODO: fix for ad portal and new roles

const DesktopNav = () => {
  const dispatch = useDispatch();
  const { user, selectedVenue, initialLoginData } = useSelector((state) => state.user);
  const roles = user.RoleIds;
  const userRoles = getUserRoleCategories(user?.RoleIds || []);

  return (
    <div className="desktop vh-100 side-container px-5">
      <ul
        className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start"
        id="menu"
      >
        <div className="text-center mx-auto">
          <img
            alt="MyGymTV Logo"
            className="py-4"
            src={GymTvLogo}
            style={{ width: '100px' }}
          />
        </div>
        {(userRoles.includes('gymUser') && userRoles.includes('admin')) &&
          <li className="nav-item">
            <Link
              className="nav-link px-sm-0 px-2"
              to="/"
            >
              <FontAwesomeIcon
                className="content-management"
                icon={faHouse}
              />
              <span className="ms-2 d-none d-sm-inline">Dashboard</span>
            </Link>
          </li>
        }
        {selectedVenue !== null && (
          <>
            {userRoles.includes('gymUser') && roles.includes(6) ?
              <li className="nav-item">
                <Link
                  className="nav-link px-sm-0 px-2"
                  to="/entertainment"
                >
                  <FontAwesomeIcon
                    className="content-management"
                    icon={faMusic}
                  />
                  <span className="ms-2 d-none d-sm-inline">Entertainment</span>
                </Link>
              </li> : null
            }
            {userRoles.includes('gymUser') && roles.includes(1) ?
              <li className="nav-item">
                <Link
                  className="nav-link px-sm-0 px-2"
                  to="/digital-signage"
                >
                  <FontAwesomeIcon
                    className="content-management"
                    icon={faImages}
                  />
                  <span className="ms-2 d-none d-sm-inline">Digital Signage</span>
                </Link>
              </li> : null
            }
            {userRoles.includes('gymUser') && roles.includes(7) ?
              <li className="nav-item">
                <Link
                  className="nav-link px-sm-0 px-2"
                  to="/scrolling-text"
                >
                  <div
                    className="icon-wrapper d-flex align-content-center"
                  >
                    <FontAwesomeIcon
                      className="fa-icon account-settnigs"
                      icon={faMessage}
                    />
                    <span className="icon-text">T</span>
                  </div>
                  <span className="ms-2">Scrolling Text</span>
                </Link>
              </li> : null
            }
          </>
        )}


        {(userRoles.includes('adUser') && !userRoles.includes('admin')) &&
          <>
            <li className="nav-item">
              <Link
                className="nav-link px-sm-0 px-2"
                to="/ad-account"
              >
                <FontAwesomeIcon
                  className="content-management"
                  icon={faUser}
                />
                <span className="ms-2 d-none d-sm-inline">Account</span>
              </Link>
            </li>

          </>
        }
        {userRoles.includes('gymUser') &&
          <li className="nav-item">
            <Link
              className="nav-link px-sm-0 px-2"
              to="/gym-account"
            >
              <FontAwesomeIcon
                className="content-management"
                icon={faUser}
              />
              <span className="ms-2 d-none d-sm-inline">Account</span>
            </Link>
          </li>
        }
        {(userRoles.includes('adUser') && !userRoles.includes('admin')) &&
          <li className="nav-item">
            <Link
              className="nav-link px-sm-0 px-2"
              to="https://chat.socialintents.com/c/mygymtv"
            >
              <FontAwesomeIcon
                className="content-management"
                icon={faHeadset}
              />
              <span className="ms-2 d-none d-sm-inline">Chat</span>
            </Link>
          </li>
        }
        {(userRoles.includes('gymUser') && !userRoles.includes('admin')) &&
          <li className="nav-item">
            <Link
              className="nav-link px-sm-0 px-2"
              to="https://chat.socialintents.com/c/clubcom"
            >
              <FontAwesomeIcon
                className="content-management"
                icon={faHeadset}
              />
              <span className="ms-2 d-none d-sm-inline">Chat</span>
            </Link>
          </li>
        }
      </ul>
    </div>
  );
};

export default DesktopNav;
