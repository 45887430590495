import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './digital-signage.scss';
import Layout from '../../../../layout/Layout';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { handleScroll } from '../../../../helpers/signageScrollHandler';
import { useDispatch } from 'react-redux';
import { deleteSignages, fetchMarketingOrderContent } from '../../../../redux/slices/myGymTV/signageSlice.js';
import moment from 'moment';



const DigitalSignage = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memoizedHandleScroll = useCallback(handleScroll, []);
  const [contentMap, setContentMap] = useState({});
  const [nowPlayingSignages, setNowPlayingSignages] = useState([]);
  const [scheduledSignages, setScheduledSignages] = useState([]);
  const [expiredSignages, setExpiredSignages] = useState([]);
  const [mediaUrls, setMediaUrls] = useState({});
  const signages = useSelector((state) => state.signage.marketingOrderContent) || [];

  const displayMedia = (contentType, url) => {
    if (contentType === 'I') {
      return (
        <img
          alt="media"
          src={url}
          style={{ maxHeight: 'auto', width: '100%' }}
        />
      )
    }
  };

  const fetchData = async (contentID) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found');
      return;
    }
    try {

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/DigitalSignage/${contentID}/content`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setMediaUrls((prev) => ({ ...prev, [contentID]: url }));
    } catch (error) {
      console.error('There was a problem!', error);
    }
  };

  useEffect(() => {
    const fetchMarketingData = async () => {
      await dispatch(fetchMarketingOrderContent());
    };

    fetchMarketingData();
  }, [dispatch]);

  const navigateToDetails = (id) => {
    navigate(`/signage-details/${id}`);
  };


  useEffect(() => {
    for (const signage of signages) {
      const contentID = signage.ContentID;
      if (contentID) {
        fetchData(contentID);
      }
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to 00:00:00

    const nowPlaying = [];
    const scheduled = [];
    const expired = [];

    for (const signage of signages) {
      const startDate = signage.StartDate ? new Date(signage.StartDate) : null;
      const endDate = signage.EndDate ? new Date(signage.EndDate) : null;
      const defaultDate = new Date('1900-01-01T00:00:00');
      if (startDate) startDate.setHours(0, 0, 0, 0);
      if (endDate) endDate.setHours(0, 0, 0, 0);

      // Now Playing Signages
      // Now Playing Signages
      if (
        signage.ContentType === 'I' && signage.ContentID && signage.IsActive &&
        (
          (!startDate || startDate <= today || startDate.getTime() === defaultDate.getTime()) &&
          (!endDate || endDate > today || endDate.getTime() === defaultDate.getTime())
        )
      ) {
        nowPlaying.push(signage);
      }

      // Scheduled Signages
      else if (
        signage.ContentType === 'I' && signage.ContentID && signage.IsActive &&
        startDate > today &&
        (!endDate || endDate > today)
      ) {
        scheduled.push(signage);
      }

      // Expired Signages
      else if (
        signage.ContentType === 'I' && signage.ContentID && !signage.IsActive ||
        (endDate && endDate < today)
      ) {
        expired.push(signage);
      }
    }

    setNowPlayingSignages(nowPlaying);
    setScheduledSignages(scheduled);
    setExpiredSignages(expired);
  }, [signages]);




  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const nowPlayingHeader = document.getElementById('now-playing-header');
    if (nowPlayingHeader) {
      nowPlayingHeader.style.display = 'block';
    }

    window.addEventListener('scroll', memoizedHandleScroll);
    return () => window.removeEventListener('scroll', memoizedHandleScroll);

  }, [memoizedHandleScroll]);

  return (
    <Layout>
      <div className="digital-signage-header">
        <div className="card-header">Digital Signage</div>
        {(nowPlayingSignages.length > 0 || scheduledSignages.length > 0 || expiredSignages.length > 0) ?
          <div>
            <div
              className="card-header"
              id="now-playing-header"
            >Now Playing ({nowPlayingSignages.length})</div>
            <div
              className="card-header"
              id="scheduled-header"
            >Coming Soon ({scheduledSignages.length})</div>
            <div
              className="card-header"
              id="expired-header"
            >Expired ({expiredSignages.length})
              <FontAwesomeIcon
                icon={faTrash}
                onClick={handleOpenModal}
                style={{ cursor: 'pointer', marginLeft: '10px' }}
              />
            </div>
          </div> : null
        }
      </div>

      {(nowPlayingSignages.length > 0 || scheduledSignages.length > 0 || expiredSignages.length > 0) ?
        <div>
          <Card className="digital-signage container border-0">
            <div
              className="signage-section"
              id="now-playing-section"
            >
              <Card.Header
                className="text-end"
                id="coming-soon-gallery-header"
              >{`Now Playing (${nowPlayingSignages.length})`}</Card.Header>
              <Card.Body>
                <div className="row">
                  {nowPlayingSignages.map((signage) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6 col-12 text-center mx-2 m-2 py-2 border rounded-1"
                      key={signage.ContentID}
                    >
                      <div className="now-playing-tag d-flex justify-content-between align-items-center w-100">
                        {signage.EndDate.includes('1900-01-01T00:00:00') ? 'Now Playing' : `Playing Until ${moment(signage.EndDate).format('MM/DD/YYYY')}`}
                        <FontAwesomeIcon
                          className="ms-2 text-white"
                          icon={faEdit}
                          onClick={() => navigateToDetails(signage.ContentID)}
                        />
                      </div>

                      {displayMedia(signage.ContentType, mediaUrls[signage.ContentID])}
                      <div>{signage.ContentName}</div>
                    </div>
                  ))}
                </div>
              </Card.Body>
            </div>
            <div
              className="signage-section"
              id="scheduled-section"
            >
              <Card.Header
                className="text-end"
                id="coming-soon-gallery-header"
              >{`Coming Soon (${scheduledSignages.length})`}</Card.Header>
              <Card.Body>
                <div className="row">
                  {scheduledSignages.map((signage) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6 col-12 text-center mx-2 m-2 py-2 border rounded-1"
                      key={signage.ContentID}
                    >
                      <div className="scheduled-tag d-flex justify-content-between align-items-center w-100">
                        Starting {moment(signage.EndDate).format('MM/DD/YYYY')}
                        <FontAwesomeIcon
                          className="ms-2 text-white"
                          icon={faEdit}
                          onClick={() => navigateToDetails(signage.ContentID)}
                        />
                      </div>

                      {displayMedia(signage.ContentType, mediaUrls[signage.ContentID])}
                      <div>{signage.ContentName}</div>
                    </div>
                  ))}

                </div>
              </Card.Body>
            </div>
            {expiredSignages.length > 0 &&
              <div
                className="signage-section"
                id="expired-section"
              >
                <Card.Header
                  className="text-end"
                  id="expired-gallery-header"
                >{`Expired (${expiredSignages.length})`}
                  {/*<FontAwesomeIcon*/}
                  {/*  icon={faTrash}*/}
                  {/*  onClick={handleOpenModal}*/}
                  {/*  style={{ cursor: 'pointer', marginLeft: '10px' }}*/}
                  {/*/>*/}
                </Card.Header>
                <Card.Body>
                  <div className="row">
                    {expiredSignages.map((signage) => (
                      <div
                        className="col-lg-3 col-md-4 col-sm-6 col-12 text-center mx-2 m-2 py-2 border rounded-1"
                        key={signage.ContentID}
                      >
                        <div className="expired-tag d-flex justify-content-between align-items-center w-100">
                          Expired {moment(signage.EndDate).format('MM/DD/YYYY')}
                          <FontAwesomeIcon
                            className="ms-2 text-white"
                            icon={faEdit}
                            onClick={() => navigateToDetails(signage.ContentID)}
                          />
                        </div>

                        {displayMedia(signage.ContentType, mediaUrls[signage.ContentID])}
                        <div>{signage.ContentName}</div>
                      </div>
                    ))}

                  </div>
                </Card.Body>
              </div>
            }
          </Card>
          <Modal
            onHide={handleCloseModal}
            show={showModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete all expired signage?</Modal.Body>
            <Modal.Footer>
              <Button
                onClick={handleCloseModal}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button

                variant="primary"
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        :
        <div className="mt-5 p-2">
          <div
            className="alert alert-secondary d-flex justify-content-between align-items-center"
            role="alert"
          >
            <FontAwesomeIcon
              className="text-secondary"
              icon={faCircleInfo}
            />
            <div className="ms-2 entertainment__action-info">You have no Digital Signage. Tap the plus icon below to get started.</div>
          </div>
        </div>
      }

    </Layout>
  );
};

export default DigitalSignage;
