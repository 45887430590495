import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import {
  setSelectedDay,
  setSelectedHour,
  setEditingChannel,
  fetchEntertainmentData
} from '../../../redux/slices/myGymTV/entertainmentSlice.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCompactDisc, faDumbbell } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../../layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import {
  getScheduleFromPreferences,
  getScheduleByDayAndHour,
  getEditingChannel
} from '../../../helpers/transformations';
import Welcome from './Welcome';

const ContentOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(moment().format('dddd MMMM Do YYYY h:mm a'));
  const { selectedVenue } = useSelector((state) => state.user);
  const { preferences, currentChannel } = useSelector((state) => state.entertainment);
  const channelSchedule = getScheduleFromPreferences(preferences);
  const currentSchedule = getScheduleByDayAndHour(channelSchedule, currentChannel);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const calculateTimeToNextMinute = () => {
      const now = moment();
      const secondsUntilNextMinute = 60 - now.seconds();
      const millisecondsUntilNextMinute = secondsUntilNextMinute * 1000;

      return millisecondsUntilNextMinute;
    };
    const updateDate = () => {
      setCurrentDate(moment().format('dddd MMMM Do YYYY h:mm a'));
    };
    const timer = setTimeout(() => {
      updateDate();
      const interval = 60 * 1000;
      setInterval(updateDate, interval);
    }, calculateTimeToNextMinute());

    return () => {
      clearTimeout(timer);
    };
  }, []);


  useEffect(() => {
    dispatch(fetchEntertainmentData()).then(() => {
      setLoading(false);
    });
  }, [selectedVenue]);


  const handleEditSelectedHour = (dayLabel, dayData, hourLabel, hourData) => {
    console.log(dayLabel, dayData, hourLabel, hourData)
    dispatch(setSelectedHour({ label: hourLabel, data: hourData }));
    dispatch(setSelectedDay({ label: dayLabel, data: dayData }));

    const editingChannel = getEditingChannel(preferences, dayLabel, hourData);

    if (editingChannel) {
      console.log(editingChannel);
      dispatch(setEditingChannel(editingChannel));
    }
    navigate('/channel-edit/edit-channel-by-hour');
  }

  return (
    <Layout className="text-left">
      {loading ?
        <div className="text-center m-5">
          <FontAwesomeIcon
            className="text-secondary"
            icon={faDumbbell}
            size="2xl"
            spin
            style={{marginTop: '150px'}}
          />
        </div> :
      <>
          {selectedVenue ?
            <Card className="border-0">
              <Card.Header
                as="h6"
                className="text-center"
              >
                {selectedVenue && currentDate}
              </Card.Header>
              <Card.Body>
                <Card.Title className="text-secondary mb-1">Now Playing</Card.Title>
                <Card.Text as="div">
                  <Card>
                    <Card.Body>
                      <Card.Title>Entertainment</Card.Title>
                      <Card.Text as="div">
                        <div className="mb-3">
                          {selectedVenue.ZoneName}
                        </div>
                        {currentChannel &&
                          <div className="border rounded-3 p-2 position-relative">
                            <div className="d-flex align-items-start py-2">
                              {currentChannel.WebIconPath ?

                                <img
                                  className="pe-4 entertainment__webIcon select-channel"
                                  src={`../../../${currentChannel.WebIconPath.toLowerCase()}`}
                                />
                                : <img
                                  className="pe-4 entertainment__webIcon select-channel"
                                  src={'../../../images/channels/unknown.png'}
                                  />

                              }
                              <div>
                                <div className="text-muted">{currentChannel.WebName}</div>
                                <div className="text-muted">{`Channel: ${currentChannel.ChannelID}`}</div>
                              </div>
                              <FontAwesomeIcon
                                className="ms-auto text-secondary position-absolute top-0 end-0 pt-4 pe-4"
                                icon={faPen}
                                onClick={() => {
                                  handleEditSelectedHour(currentSchedule.DayLabel, currentSchedule.DayInt, currentSchedule.HourRange, currentSchedule.Hour);
                                }}
                              />
                            </div>
                            <div className="text-muted">
                              {currentChannel.WebDescription}
                            </div>
                          </div>

                        }
                      </Card.Text>
                    </Card.Body>
                  </Card>

                </Card.Text>
              </Card.Body>
            </Card >
            :
            <Welcome />
          }
        </>
      }

    </Layout>
  )
};

export default ContentOverview;
