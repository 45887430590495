import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import '../order-tracker.scss';

const SupportInfo = () => {
  const { initialLoginData } = useSelector((state) => state.user);
  const support = initialLoginData?.SalesRep;

  return (
    <>
    { support ? (
        <Card className="m-2 col-lg-5 col-md-10 col-sm-10 border-0 mx-auto">
          <Card.Header className="account-card-header d-flex justify-content-between align-items-center">
            <div>
              Client Services Support
            </div>
            <FontAwesomeIcon
              className="account-icon"
              icon={faHeadset}
            />
          </Card.Header>
          <Card.Body className="order-status p-2">
            {support.LSSName &&
              <div className="row mb-1">
                <div className="col-4"><strong>Name:</strong></div>
                <div className="col">{support.LSSName}</div>
              </div>
            }
            {support.LSSPhone &&
              <div className="row mb-1">
                <div className="col-4"><strong>Phone:</strong></div>
                <div className="col">{support.LSSPhone}</div>
              </div>
            }
            {support.LSSEmail &&
              <div className="row mb-1">
                <div className="col-4"><strong>Email:</strong></div>
                <div className="col"><a href={`mailto:${support.LSSEmail}`}>{support.LSSEmail}</a></div>
              </div>
            }
          </Card.Body>
        </Card>
      ) : 
        <p>No support info, or data is loading</p>
      }
    </>

  );
};

export default SupportInfo;
