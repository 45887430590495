import React from 'react';
import Card from 'react-bootstrap/Card';
import '../order-tracker.scss';
import StepTracker from './StepTracker';

const UnderReview = ({ activeKey, handleSetActiveKey }) => {
  // State for handling form inputs
  // Add additional states as necessary

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the form submission logic here
  };

  return (
    <div className="status-bg">
      <StepTracker
        activeKey={activeKey}
        handleSetActiveKey={handleSetActiveKey}
      />
      <Card className="border-0 status-description">
        <Card.Body className="pt-0">
          <Card.Title
            className="step-title text-center d-flex justify-content-center align-items-center"
          >
            Under Review
          </Card.Title>
          <Card.Text>
            A designer is now working on your ad!
          </Card.Text>

        </Card.Body>
      </Card>
    </div>

  );
};

export default UnderReview;
