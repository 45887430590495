import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getInitialLoginData } from '../../../redux/slices/userSlice';
import { Modal, Form, Button } from 'react-bootstrap';
import { resendStatusEmail } from '../../../redux/slices/adPortal/advertiserAdminSlice';
import axios from 'axios';

const EditEmail = ({ show, handleClose, currentAccount, onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const { initialLoginData, loading: initialLoginDataLoading } = useSelector((state) => state.user);
  const [editEmail, setEditEmail] = useState('');
  const [resendStatusEmail, setResendStatusEmail] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState('');
  const [message, setMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchInitialLoginData = async () => {
      if (currentAccount) {
        const userData = {
          UserID: currentAccount.UserID,
          RoleIds: [11]
        };
        await dispatch(getInitialLoginData(userData));
      }
    };

    fetchInitialLoginData();
  }, [dispatch, currentAccount]);

  useEffect(() => {
    if (initialLoginData) {
      console.log('Updated advertiserAccount:', initialLoginData.Account);
    }
  }, [initialLoginData]);

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedAdvertiserAccount = initialLoginData?.Account;
    const agreements = initialLoginData?.Agreements;

    if (!updatedAdvertiserAccount || !agreements) {
      setMessage('Unable to retrieve account information.');
      setModalTitle('Error');
      setLoading(false);
      return;
    }

    if (editEmail !== confirmEmail) {
      setMessage('Emails do not match. Please try again.');
      setModalTitle('Error');
      setLoading(false);
      return;
    }

    const accountData = {
      UID: updatedAdvertiserAccount.UID,
      Name: updatedAdvertiserAccount.Name,
      AdvertiserCode: updatedAdvertiserAccount.AdvertiserCode,
      ContactName: updatedAdvertiserAccount.ContactName,
      Address: updatedAdvertiserAccount.Address,setResendStatusEmail,
      City: updatedAdvertiserAccount.City,
      State: updatedAdvertiserAccount.State,
      PostalCode: updatedAdvertiserAccount.PostalCode,
      Country: updatedAdvertiserAccount.Country,
      Phone: updatedAdvertiserAccount.Phone,
      EmailAddress: editEmail,
      EmailUpdated: true
    };

    const jsonString = JSON.stringify(accountData);
    try {
      const response = await submitAdTrackerUpdate(jsonString, agreements[0].AgreementID);
      console.log(response);
      if (response.success) {
        if (resendStatusEmail) {
          dispatch(resendStatusEmail(editEmail));
        }
        setMessage('Account updated successfully!');
        setModalTitle('Success');
        onUpdateSuccess(accountData);
      } else {
        setMessage('Failed to update account.');
        setModalTitle('Error');
      }
    } catch (error) {
      console.error('Error updating account:', error);
      setMessage('An error occurred during account update.');
      setModalTitle('Error');
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const submitAdTrackerUpdate = async (jsonString, agreementID) => {
    const token = localStorage.getItem('token');
    const workQueueItemData = {
      TypeID: 102,
      AgreementID: parseInt(agreementID, 10),
      UserID: currentAccount.UserID,
      Data: jsonString
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/CRM/SubmitAdTrackerUpdate`, workQueueItemData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Ad Tracker Update submitted successfully');
      return response.data;
    } catch (error) {
      console.error('Error submitting Ad Tracker Update:', error);
      throw error;
    }
  };

  return (
    <Modal
centered
onHide={handleClose}
show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleEditSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>New Email Address</Form.Label>
            <Form.Control
              disabled={loading}
              onChange={(e) => setEditEmail(e.target.value)}
              required
              type="email"
              value={editEmail}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Confirm Email Address</Form.Label>
            <Form.Control
              disabled={loading}
              onChange={(e) => setConfirmEmail(e.target.value)}
              required
              type="email"
              value={confirmEmail}
            />
          </Form.Group>
          <Form.Group
className="mb-3"
            controlId="formResendEmail"
          >
            <Form.Check
              checked={resendStatusEmail}
              label="Also resend welcome email"
              onChange={(e) => setResendStatusEmail(e.target.checked)}
              type="checkbox"
            />
          </Form.Group>
          <Button
            disabled={editEmail !== confirmEmail || editEmail === currentAccount.Email || loading}
            type="submit"
            variant="primary"
          >
            {loading ? 'Updating...' : 'Update Email'}
          </Button>
        </Form>
        {message && <div className={`alert mt-2 ${modalTitle === 'Error' ? 'alert-danger' : 'alert-success'}`}>{message}</div>}
      </Modal.Body>
    </Modal>
  );
};

export default EditEmail;
