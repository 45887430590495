import moment from 'moment';


export function formatHour(hour) {
  // Convert 24-hour format hour to 12-hour format
  const convertedHour = hour > 12 ? hour - 12 : (hour === 0 ? 12 : hour);

  const startHour = moment(hour, 'HH').format('h:mm A');
  return startHour;
}

// A mapping object to convert role IDs to role names
export const roleMapping = {
  1: 'Advertisements',
  2: 'Can Upload Custom Images',
  3: 'Content Manager',
  4: 'Custom Images',
  5: 'Group Exercise',
  6: 'Music Entertainment',
  7: 'Scrolling Text',
  8: 'Staff',
  10: 'Admin',
};



export const getCurrentPartOfDay = () => {
  const currentHour = new Date().getHours();
  if (currentHour >= 4 && currentHour < 9) return 'Early Morning';
  if (currentHour >= 9 && currentHour < 11) return 'Morning';
  if (currentHour >= 11 && currentHour < 13) return 'Lunch';
  if (currentHour >= 13 && currentHour < 16) return 'Afernoon';
  if (currentHour >= 16 && currentHour < 20) return 'Early Evening';
  if ((currentHour >= 20 && currentHour < 4) || currentHour >= 21) return 'Late Night';

}

export const getHourFromRange = (range) => {
  if (range) {
    const [startHourString] = range.split(' - ');
    const startHour = moment(startHourString, 'h:mm A');
    return startHour.hours();
  }
}

export const dayOfWeekToInt = {
  'Sunday': 1,
  'Monday': 2,
  'Tuesday': 3,
  'Wednesday': 4,
  'Thursday': 5,
  'Friday': 6,
  'Saturday': 7
};

export const getDayOfWeekAsInt = (dayOfWeek) => {
  return dayOfWeekToInt[dayOfWeek];
}


export function convertDayToDayOfWeek(day) {
  switch (day) {
    case 1:
      return 'Sunday';
    case 2:
      return 'Monday';
    case 3:
      return 'Tuesday';
    case 4:
      return 'Wednesday';
    case 5:
      return 'Thursday';
    case 6:
      return 'Friday';
    case 7:
      return 'Saturday';
    default:
      return 'Unknown';
  }
}


export function convertDayPartIDToPeriod(dayPartID) {
  switch (dayPartID) {
    case 1:
      return 'Early Morning';
    case 2:
      return 'Morning';
    case 3:
      return 'Afternoon';
    case 4:
      return 'Evening';
    case 5:
      return 'Night';
    case 6:
      return 'Late Night';
    default:
      return 'Unknown';
  }
}

export const getCurrentChannel = (preferences) => {
  const date = new Date();
  const currentDay = date.getDay() === 0 ? 1 : date.getDay() + 1;
  const currentHour = date.getHours();

  const currentChannel = preferences.filter(item =>
    item.Day === currentDay &&
    item.Hour === currentHour
  );

  if (currentChannel.length > 0) {
    return {
      WebName: currentChannel[0].Name,
      ChannelID: currentChannel[0].ID,
      DayInt: currentChannel[0].Day,
      DayLabel: convertDayToDayOfWeek(currentChannel[0].Day),
      Hour: currentChannel[0].Hour,
      HourRange: formatHour(currentChannel[0].Hour),
      PartOfDay: convertDayPartIDToPeriod(currentChannel[0].DayPartID),
      WebIconPath: currentChannel[0].WebIconPath,
      WebDescription: currentChannel[0].WebDescription
    };
  } else {
    console.log('error: channel not set.')
  }
}

export const getEditingChannel = (preferences, editDayLabel, editHourData) => {
  const dayMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayInt = dayMap.indexOf(editDayLabel) + 1;
  console.log(typeof (editHourData))
  console.log(dayInt, editHourData)
  let editingChannel;
  if (typeof (editHourData) === 'object') {
    editingChannel = preferences.filter(item =>
      item.Day === dayInt &&
      item.Hour === editHourData[0].hour
    )
  } else {
    editingChannel = preferences.filter(item =>
      item.Day === dayInt &&
      item.Hour === editHourData
    )
  }


  if (editingChannel.length > 0) {
    return {
      WebName: editingChannel[0].Name,
      ChannelID: editingChannel[0].ID,
      DayInt: editingChannel[0].Day,
      DayLabel: convertDayToDayOfWeek(editingChannel[0].Day),
      Hour: editingChannel[0].Hour,
      HourRange: formatHour(editingChannel[0].Hour),
      PartOfDay: convertDayPartIDToPeriod(editingChannel[0].DayPartID),
      WebIconPath: editingChannel[0].WebIconPath,
      WebDescription: editingChannel[0].WebDescription
    };
  } else {
    console.log('error: channel not set.')
  }
}


export const getScheduleFromPreferences = (preferences) => {
  const channelSchedule = preferences.reduce((acc, pref) => {
    const { Day, Hour, ID, Name, WebIconPath } = pref;
    const day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][Day - 1];
    const hour = Hour;
    const hour24 = Hour + 12;
    if (!acc[day]) acc[day] = {};
    if (!acc[day][hour]) acc[day][hour] = [];

    acc[day][hour].push({ channel_id: ID, channel_name: Name, icon: WebIconPath, hour: Hour });

    return acc;
  }, {});
  return channelSchedule;
};

export const getScheduleByDayAndHour = (channelSchedule, currentChannel) => {
  if (!currentChannel) {
    return null;
  }

  const { DayLabel: currentDayLabel, Hour: currentHour, DayInt: currentDayInt } = currentChannel;

  const daySchedule = channelSchedule[currentDayLabel];

  if (daySchedule) {
    for (const hour in daySchedule) {
      if (daySchedule[currentHour]) {
        return {
          ...daySchedule[hour][0],
          Hour: currentHour,
          DayLabel: currentDayLabel,
          DayInt: currentDayInt,
        };
      }
    }
  }

  return null;
}

export const getDaySchedule = (channelSchedule, dayLabel) => {
  const dayMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayInt = dayMap.indexOf(dayLabel) + 1;
  const daySchedule = channelSchedule[dayLabel];
  if (!daySchedule) return null;
  const allChannels = [];
  for (const hour in daySchedule) {
      const channels = daySchedule[hour].map(channel => ({
        DayInt: dayInt,
        HourInt: parseInt(hour),
        ChannelID: channel.channel_id
      }));

      allChannels.push(...channels);
  }
  return allChannels;
}

export const getPreferenceByDayAndHour = (preferences, day, hour) => {
  return preferences.find(preference => preference.Day === day && preference.Hour === hour);
};
