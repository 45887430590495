import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Form, Button, ListGroup, FormControl, Modal, DropdownButton, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faUserPlus, faHistory, faInfoCircle, faCircleExclamation, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import Layout from '../../../layout/Layout';
import { getInitialLoginData, registerUser } from '../../../redux/slices/userSlice';
import { fetchAdvertisers } from '../../../redux/slices/adPortal/advertiserAdminSlice';
import EditEmail from './EditEmail';
import PasswordResetModal from './PasswordResetModal';
import ResendEmail from './ResendStatusEmail';
import '../order-tracker.scss';

const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [advertiserCode, setAdvertiserCode] = useState('');
  const [filter, setFilter] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [showResendEmailModal, setShowResendEmailModal] = useState(false);
  const [currentAccount, setCurrentAccount] = useState({});
  const [message, setMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const { advertisers, loading: advertisersLoading } = useSelector((state) => state.advertiserAdmin);
  const { initialLoginData, loading: userLoading } = useSelector((state) => state.user);

  const advertiserAccount = initialLoginData?.Account;

  const addSpacesBetweenCasing = (str) => {
    return str.replace(/([A-Z])/g, ' $1').trim();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userDetails = { Email: email, AdvertiserCode: advertiserCode };

    try {
      const response = await dispatch(registerUser(userDetails));
      if (response.payload && response.payload.Token) {
        setMessage('Account created successfully!');
        setModalTitle('Success');
      } else if (response.payload) {
        setMessage(response.payload);
        setModalTitle('Error');
      }
      setShowModal(true);
    } catch (error) {
      setMessage('An error occurred during account creation.');
      setModalTitle('Error');
      setShowModal(true);
    }

    setEmail('');
    setAdvertiserCode('');
  };

  const handleSearchHistory = () => {
    dispatch(fetchAdvertisers(filter));
  };

  const handleOpenEditModal = (account) => {
    setCurrentAccount(account);
    setShowEditModal(true);
  };

  const handleUpdateSuccess = (updatedAccount) => {
    setShowEditModal(false);
    setCurrentAccount({});
  };

  const handleViewAs = async (account) => {
    const userData = {
      UserID: account.UserID,
      RoleIds: [parseInt(account.RoleID, 10)],
    };

    const response = await dispatch(getInitialLoginData(userData));

    if (response.payload) {
      const initialLoginData = response.payload;
      const agreementID = initialLoginData.Agreements?.[0]?.AgreementID;
      navigate(`/order-status/${agreementID}`, { state: { initialLoginData } });
    }
  };

  const handleSendPasswordReset = (account) => {
    setCurrentAccount(account);
    setShowPasswordResetModal(true);
  };

  const handleResendEmail = (account) => {
    setCurrentAccount(account);
    setShowResendEmailModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClosePasswordResetModal = () => {
    setShowPasswordResetModal(false);
    setCurrentAccount({});
  };

  const handleCloseResendEmailModal = () => {
    setShowResendEmailModal(false);
    setCurrentAccount({});
  };

  return (
    <Layout>
      <Card className="my-3">
        <Card.Header>
          <FontAwesomeIcon
className="me-2"
icon={faUserPlus}
          /> Create Account
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group
className="mb-3"
controlId="formBasicEmail"
            >
              <Form.Label>Email address</Form.Label>
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
                required
                type="email"
                value={email}
              />
            </Form.Group>

            <Form.Group
className="mb-3"
controlId="formBasicAdvertiserCode"
            >
              <Form.Label>Customer Code</Form.Label>
              <Form.Control
                onChange={(e) => setAdvertiserCode(e.target.value)}
                placeholder="ex. ABC123-US"
                required
                type="text"
                value={advertiserCode}
              />
            </Form.Group>

            <Button
type="submit"
variant="primary"
            >Submit</Button>
          </Form>
        </Card.Body>
      </Card>

      <Card className="mt-3 history-card">
        <Card.Header>
          <div className="d-flex justify-content-start align-items-center">
            <div>
              <FontAwesomeIcon
className="me-2"
icon={faHistory}
              /> History
            </div>
            <FontAwesomeIcon
className="ms-2 history-info"
icon={faInfoCircle}
            />
          </div>
          <FormControl
            className="mt-2"
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Search for Accounts"
            type="text"
            value={filter}
          />
          <Button
className="mt-2"
onClick={handleSearchHistory}
variant="primary"
          >Search</Button>
        </Card.Header>
        <ListGroup variant="flush">
          {advertisersLoading ? (
            <ListGroup.Item>Loading...</ListGroup.Item>
          ) : (
            advertisers.map((item, index) => (
              <React.Fragment key={index}>
                <ListGroup.Item>
                  <div>Email: {item.Email}
                    <FontAwesomeIcon
                      className="me-2 text-secondary ms-2"
                      icon={faPencil}
                      onClick={() => handleOpenEditModal(item)}
                    />
                  </div>
                  <div>User ID: {item.UserID}</div>
                  <div>Customer Code: {item.AdvertiserCode}</div>
                  <div>Created On: {new Date(item.CreatedOn).toLocaleDateString()}</div>
                  <div>Password Created:
                    {item.PasswordCreated ? (
                      <FontAwesomeIcon
                        className="text-success ms-2"
                        icon={faCheckCircle}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="text-danger ms-2"
                        icon={faTimesCircle}
                      />
                    )}
                  </div>
                  <div>Current Status: {addSpacesBetweenCasing(item.AdStatus)}</div>
                  <DropdownButton
                    className="mt-2"
                    id="dropdown-basic-button"
                    title="Actions"
                    variant="outline-success"
                  >
                    <Dropdown.Item onClick={() => handleViewAs(item)}>View as Customer</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSendPasswordReset(item)}>Send Password Reset</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleResendEmail(item)}>Resend Welcome Email</Dropdown.Item>                
                  </DropdownButton>
                </ListGroup.Item>
              </React.Fragment>
            ))
          )}
        </ListGroup>
      </Card>

      <EditEmail
        currentAccount={currentAccount}
        handleClose={() => setShowEditModal(false)}
        onUpdateSuccess={handleUpdateSuccess}
        show={showEditModal}
      />

      <PasswordResetModal
        handleClose={handleClosePasswordResetModal}
        initialEmail={currentAccount.Email}
        show={showPasswordResetModal}
      />

      <ResendEmail
        handleClose={handleCloseResendEmailModal}
        initialAdvertiserCode={currentAccount.AdvertiserCode}
        show={showResendEmailModal}
      />

      <Modal
centered
onHide={handleCloseModal}
show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon
className="text-white"
icon={faCircleExclamation}
size="2xl"
            /> {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message}
        </Modal.Body>
        <Modal.Footer>
          <Button
onClick={handleCloseModal}
variant="secondary"
          >Close</Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Admin;
