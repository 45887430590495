import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const decodedToken = jwtDecode(token);

      // Check if the token has expired
      if (decodedToken.exp * 1000 < Date.now()) {
        // Token has expired, remove it from storage
        localStorage.removeItem('token');
        setUser(null);
      } else {
        // Token is valid, set the user
        setUser(decodedToken);
      }
    } else {
      setUser(null);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token'); 
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, setUser, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
}

export { AuthContext, AuthProvider };
